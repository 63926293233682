import React, {useState} from "react";
import {getTimePeriod, handleTip, humanizePrice} from "./utils";

function SupplierFamilyTree({filters, setFilters, data, term, postFilters}) {
    const [isCurrent, setIsCurrent] = useState(true);

    const suppTree = data[Object.keys(data).toReversed().slice(isCurrent ? 0 : 1, isCurrent ? 1 : 2)[0]]?.reduce((prev, curr) => {
        if (!(curr.supplier in prev)) prev[curr.supplier] = {};
        let x = curr.raw_supplier || curr.supplier;
        if (x in prev[curr.supplier]) prev[curr.supplier][x] += curr.total_amount;
        else prev[curr.supplier][x] = curr.total_amount;
        return prev;
    }, {}) || {};

    const activeTab = term === 0 ? "Year" : term === 1 ? "Quarter" : "Month";

    return <div id={"supp-ftree"} className={"flex flex-col card aspect-[0.8] lg:aspect-square flex-1 lg:max-h-96 gap-2 overflow-hidden"}>
        <div className={"flex flex-col item-start w-full whitespace-nowrap"}>
            <div className={"flex items-center justify-between relative"}>
                <span className={"font-bold text-gray-800 text-md"}>Supplier Family Wise Spend</span>

                <div className={"flex items-center gap-4"}>
                    <div className={"flex items-center text-md border rounded-lg overflow-hidden"}>
                        <button onClick={() => setIsCurrent(!isCurrent)}
                                className={"material-symbols-rounded rounded-l-lg hover:bg-gray-100 p-1 text-md disabled:opacity-50"}
                                disabled={!isCurrent}>chevron_left
                        </button>
                        <button onClick={() => setIsCurrent(!isCurrent)}
                                className={"material-symbols-rounded rounded-r-lg hover:bg-gray-100 p-1 text-md disabled:opacity-50"}
                                disabled={isCurrent}>chevron_right
                        </button>
                    </div>
                    <div onMouseEnter={(e) => handleTip(e, "supp-ftree")}
                         onMouseLeave={(e) => handleTip(e, "supp-ftree")}
                         className={"show-tip"}>
                        <span className={"material-symbols-rounded text-md"}>info</span>
                        <div className={"tool-tip-cover"}>
                    <span
                        className={"p-2 block px-4 whitespace-normal text-sm w-[20rem]"}>{`The following graph shows spending suppliers in ${activeTab} (${getTimePeriod(term)})`}</span>
                        </div>
                    </div>
                </div>
            </div>
            <span className={"text-xs text-gray-600"}>{isCurrent ? 'Latest' : 'Previous'} {activeTab}</span>
        </div>
        <div className={"w-full overflow-auto flex light-scroll gray-scroll h-full"}>
            <table className={"w-full flex flex-col"}>
                <tbody>
                {
                    Object.keys(suppTree).map(k => {
                            const total = Object.values(suppTree[k]).reduce((a, b) => a + b, 0);
                            return <tr key={k} className={"flex items-start w-full border"}>
                                <td onClick={() => { setFilters({...filters, supplier: k}) }} title={`Filter by ${k}`}
                                    className={"text-xs flex flex-[0.3] p-1 whitespace-nowrap overflow-hidden cursor-pointer"}>
                                    <span className={"overflow-ellipsis overflow-hidden"}>{k}</span>
                                </td>
                                <td className={"flex flex-[0.7]"}>
                                    <table className={"flex flex-col w-full"}>
                                        <tbody>
                                        {
                                            Object.keys(suppTree[k]).map(sk => {
                                                return <tr key={sk}
                                                           className={"flex items-stretch flex-1 overflow-hidden border"}>
                                                    <td onClick={() => {
                                                        if (filters?.raw_supplier === sk) {
                                                            let d = {...filters};
                                                            delete d.raw_supplier;
                                                            setFilters(d);
                                                        } else {
                                                            setFilters({...filters, raw_supplier: sk})
                                                        }
                                                    }} title={`Filter by ${sk}`}
                                                        className={"text-xs flex flex-1 p-1 whitespace-nowrap overflow-hidden cursor-pointer"}>
                                                        <span className={"overflow-ellipsis overflow-hidden"}>{sk}</span>
                                                    </td>
                                                    <td className={"text-sm flex flex-col flex-1 p-1"}
                                                        title={`${(suppTree[k][sk] * 100 / total).toFixed(2)}%`}>
                                                        <div
                                                            className={"w-full bg-gray-200 rounded-md overflow-hidden flex-1 flex items-center relative"}>
                                                        <span
                                                            className={"absolute text-xss right-0 p-1 font-semibold"}>{`$${humanizePrice(suppTree[k][sk])}`}</span>
                                                            <span className={"bg-blue-300 block h-full"}
                                                                  style={{width: `${(suppTree[k][sk] * 100 / total).toString()}%`}}></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        }
                    )
                }
                </tbody>
            </table>
        </div>
    </div>
}

export default SupplierFamilyTree;