import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Img from "../../components/Img";
import Notify from "../../components/Notification";
import {approveOrder, getOrderRequest} from "../../api/dashboard";
import {unitizeAmount} from "./admin/graphs/utils";


export default function OrderRequest() {
    const navigate = useNavigate();
    const [cart, setCart] = useState(null);
    const [loader, setLoader] = useState(true);
    const {cartID} = useParams();
    const [approvals, setApprovals] = useState({});
    const [btnLoader, setBtnLoader] = useState(false);


    // fetching order details
    useEffect(() => {
        setLoader(true);
        getOrderRequest(cartID)
            .then(([status, data]) => {
                if (status === 200) {
                    setCart(data);
                }
            })
            .finally(() => setLoader(false));
    }, []);

    const handleSubmit = () => {
        setBtnLoader(true)
        approveOrder({
            cart_id: cartID.toString(),
            status: approvals
        }).then(([status, data]) => {
            if (status === 200) {
                navigate("/staging-app/dashboard/success", {
                    state: {
                        message: `${cart.products.length === 1 ? "Order" : "Cart"} approved successfully!`,
                        details: `Cart Value: ${unitizeAmount(cart.products.reduce((prev, curr) => prev + curr.best_price, 0))}`,
                        ref: window.location.href
                    }
                })
            } else {
                Notify("Something went wrong", "err");
                console.log(data);
            }
        }).finally(() => setBtnLoader(false));
    }


    return <div className={"flex items-center justify-between flex-col w-full flex-1 px-2 bg-gray-100 relative gap-8"}>
        <div className={"flex items-center justify-between gap-2 w-full"}>
            <div className={"p-2"}>
                <span className={"flex text-xl gap-1 w-full"}><b>Order</b> Approval</span>
            </div>
        </div>
        <div className={"flex w-full flex-1 flex-col items-center justify-center px-2"}>
            {
                loader &&
                <div className={"flex flex-1 w-full items-center justify-center"}>
                    <span className={"material-symbols-rounded animate-spin"}>progress_activity</span>
                </div>
            }
            {
                cart &&
                <div className={"flex flex-col flex-1 w-full gap-4"}>
                    <div className={"flex flex-col w-full text-sm"}>
                        <span className={"font-medium text-gray-600"}>Requester's Name: <b
                            className={"text-black"}>{cart.user.name}</b></span>
                        <span
                            className={"font-medium text-gray-600"}>{cart.products.length === 1 ? "Order" : "Cart"} ID: <b>#{cart.id}</b></span>
                    </div>
                    <div className={"w-full card p-4"}>
                        <div
                            className={"flex items-center w-full text-xs font-medium bg-gray-100 rounded-md border-b p-5"}>
                            <span className={"flex-[4]"}>Description</span>
                            <span className={"flex-[1]"}>Quantity</span>
                            <span className={"flex-[1]"}>Price</span>
                            <span className={"flex-[1]"}>Location</span>
                            <span className={"flex-[2]"}>Expected Delivery Date</span>
                            <span className={"flex-1"}>Approval Status</span>
                        </div>
                        <ul className={"w-full flex flex-col p-5 gap-4"}>
                            {
                                cart.products.map(product =>
                                    <li key={`${cart.id}-${product.order_id}`}
                                        className={"flex items-center w-full text-xs font-medium"}>
                                        <div className={"flex items-center flex-[4] gap-4"}>
                                            <Img src={product.product_image} className={"w-16"}/>
                                            <div className={"flex flex-col items-start gap-1"}>
                                                <span className={"font-semibold text-sm"}>{product.product_name}</span>
                                                <span className={"text-xs gap-1 flex items-center"}>
                                                    <span className={"font-semibold text-green-500"}>Best Price: </span>
                                                    <span
                                                        className={product.discounted_unit_price ? "line-through text-gray-700" : ""}>{unitizeAmount(product.unit_price)}</span>
                                                    {!!product.discounted_unit_price &&
                                                        <span>{unitizeAmount(product.discounted_unit_price)}</span>}
                                                </span>
                                            </div>
                                        </div>
                                        <span className={"flex-[1]"}>x{product.quantity}</span>
                                        <span
                                            className={"flex-[1] font-bold"}>{unitizeAmount(product.best_price)}</span>
                                        <span
                                            className={"flex-[1]"}>{product.location.city}, {product.location.state}</span>
                                        <span
                                            className={"flex-[2]"}>{new Date(product.expected_delivery_date.split("T")[0]).toDateString()}</span>
                                        <span className={"flex-1"}>
                                            <button onClick={() => {
                                                console.log(approvals);
                                                if (approvals[product.order_id] === undefined)
                                                    setApprovals({...approvals, [product.order_id]: true})
                                                else
                                                    setApprovals({
                                                        ...Object.fromEntries(
                                                            Object.entries(approvals).filter(x =>
                                                                x[0].toString() !== product.order_id.toString()))
                                                    })
                                            }}
                                                    className={`flex items-center gap-2 rounded-md shadow ${approvals[product.order_id] === true ? "bg-lime-400" : "bg-gray-100"} px-3 py-2`}>
                                                Approve
                                                <span className={"material-symbols-rounded text-sm"}>check_circle</span>
                                            </button>
                                        </span>
                                    </li>)
                            }
                        </ul>
                    </div>
                    <div className={"w-full flex items-center justify-end"}>
                        {
                            cart.pending_approval ?
                                <button onClick={handleSubmit} disabled={btnLoader}
                                        className={"px-8 py-2 bg-lime-400 text-xs font-semibold rounded-md"}>
                                    {
                                        btnLoader ?
                                            <span
                                                className={"material-symbols-rounded text-sm"}>progress_activity</span>
                                            :
                                            "Submit"
                                    }
                                </button>
                                :
                                <button disabled={true}
                                        className={"px-8 py-2 bg-gray-100 text-xs font-semibold rounded-md"}>
                                    Already Processed
                                </button>
                        }
                    </div>
                </div>
            }
        </div>

    </div>

}