import {Link, useLocation, useNavigate} from "react-router-dom";
// import SuccessGIF from "../static/img/success.gif";
import SuccessGIF from "../static/img/success-1.gif";

export default function SuccessPage() {

    const navigate = useNavigate()
    const location = useLocation();

    if (!location.state)
        navigate("/staging-app/dashboard");

    return (
        <>
            <div className={"flex flex-col items-center justify-center h-full w-full mb-24"}>

                <img src={SuccessGIF} alt={"success-gif"} className={"max-w-[100px]"} />

                <span className={"font-medium text-accent text-md"}>{location.state.message}</span>
                <span className={"font-bold text-lg my-2 text-accent"}>{location.state.details}</span>

                <div className={"flex items-center flex-wrap justify-center gap-4 mt-6"}>

                    <Link to={"/staging-app/dashboard"} className={"px-4 py-3 rounded-md text-accent font-bold bg-nimbey"}>Go
                        Home</Link>

                    <Link to={location.state.ref} className={"px-4 py-3 rounded-md text-white font-bold bg-accentBack"}>Go
                        Back</Link>
                </div>
            </div>
        </>
    )
}