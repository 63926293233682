import React, {useEffect, useState} from "react";
import Notify from "./Notification";
import {analysisDetails, contractDetails} from "../api/dashboard";

function AnalysisSideBar({record_id, setDescribeAnalysis}) {
    const [arRecord, setArRecord] = useState({});
    const [tab, setTab] = useState(0);

    useEffect(() => {
        analysisDetails(record_id)
            .then(([status, data]) => {
                if (status === 200) {
                    setArRecord(data);
                } else {
                    if (status === 404) {
                        Notify("No record_id found for this analysis", "alert");
                    } else {
                        Notify("Something went wrong while fetching details", "alert");
                    }
                    setArRecord({});
                }
            })
    }, [record_id]);

    if (arRecord)
    return <div
        className={"flex items-stretch fixed h-full lg:max-w-screen-sm w-full shadow-xl bg-white right-0 bottom-0 z-50" +
            " flex-1 overflow-x-auto lg:overflow-hidden lg:overflow-y-auto light-scroll"}>
        {/*  borders */}
        <div className={"flex flex-col w-[5px] h-full"}>
            {
                ["#005B8C", "#00A7C9", "#A58EFD", "#005162"].map(color =>
                    <div className={"h-full w-[5px]"} style={{backgroundColor: color}}/>
                )
            }
        </div>

        {/*  content  */}
        <div className={"flex flex-col w-full pt-6 px-6 flex-1 h-full"}>
            {/* side header and close button */}
            <div className={"flex items-center justify-between mb-4"}>
                <div className={"overflow-hidden text-ellipsis w-3/4"}><span
                    className={"font-bold text-md text-nowrap"}>{arRecord.invoice_number ? `Invoice Number: ${arRecord.invoice_number}` : `Record: ${record_id}`}</span>
                </div>
                <button onClick={() => setDescribeAnalysis(null)}><span
                    className={"material-symbols-rounded text-md"}>close</span></button>
            </div>

            <div className={"flex items-center border-b my-4 gap-4"}>
                <button onClick={() => setTab(0)}
                        className={`font-bold text-sm ${tab === 0 ? 'border-b border-black' : 'text-gray-400'} text-center px-3 pb-2`}>
                    Analysis Details
                </button>
                <button onClick={() => setTab(1)}
                        className={`font-bold text-sm ${tab === 1 ? 'border-b border-black' : 'text-gray-400'} text-center px-3 pb-2`}>
                    Other Information
                </button>
            </div>

            {
                tab === 0 ?
                    <>
                        {/* analysis details */}
                        <div className={"flex flex-col w-full gap-2"}>
                            <div className={"flex items-center justify-between"}>
                                <span className={"flex-1 font-bold"}>Invoice Number:</span>
                                <span className={"flex-1"}>{arRecord.invoice_number}</span>
                            </div>
                            <div className={"flex items-center justify-between"}>
                                <span className={"flex-1 font-bold"}>PO Number:</span>
                                <span className={"flex-1"}>{arRecord.po_number}</span>
                            </div>
                            <div className={"flex items-center justify-between"}>
                                <span className={"flex-1 font-bold"}>Priority:</span>
                                <span className={"flex-1 capitalize"}>{arRecord.priority}</span>
                            </div>
                            <div className={"flex items-center justify-between"}>
                                <span className={"flex-1 font-bold"}>Remittance Advice Status:</span>
                                <span className={"flex-1 capitalize"}>{arRecord.ra_status}</span>
                            </div>
                            <div className={"flex items-center justify-between"}>
                                <span className={"flex-1 font-bold"}>Recoverable Amount:</span>
                                <span className={"flex-1 font-bold"}>{arRecord.recoverable_amount}</span>
                            </div>
                            <div className={"flex items-center justify-between"}>
                                <span className={"flex-1 font-bold"}>Uploaded on:</span>
                                <span
                                    className={"flex-1"}>{arRecord.created_at ? new Date(arRecord.created_at).toDateString() : "-"}</span>
                            </div>
                        </div>

                        {/* other information */}
                        <div className={"flex flex-col w-full"}>
                            <span className={"text-gray-400 font-bold my-4 text-sm border-b pb-2"}>Summary</span>
                            <p className={"text-black font-normal"}>
                                {
                                    (() => {
                                        try {
                                            const obj = JSON.parse(arRecord.summary)
                                            return obj.summary;
                                        } catch (e) {
                                            console.log(e);
                                            return arRecord.summary || "-"
                                        }
                                    })()
                                }
                            </p>
                        </div>
                    </>
                    :
                    <div className={"flex items-stretch flex-wrap w-full gap-8 pt-5"}>
                        {
                            arRecord.details.map(doc =>
                                doc && doc.id &&
                                <div key={doc.id} className={"flex flex-col flex-1 gap-2 mb-4"}>
                                    <span className={"border-b-2 text-sm font-medium text-gray-600"}>{doc.name}</span>
                                    {
                                        doc.parameters && doc.parameters.slice(0, doc.parameters.length > 4 ? 4 : undefined)
                                            .filter(p => p.type !== "object")
                                            .map(p =>
                                            <div key={p.id} id={p.id} className={"flex items-center justify-between whitespace-nowrap gap-4"}>
                                                <span className={"flex-1 font-bold capitalize"}>{p.name.replaceAll("_", " ")}:</span>
                                                {
                                                    p.type === "date" ?
                                                        <span
                                                            className={""}>{new Date(p.value).toLocaleDateString(
                                                            "default", {
                                                                day: "2-digit",
                                                                month: "short",
                                                                year: "numeric"
                                                            })}</span>
                                                        :
                                                        p.type === "currency" ?
                                                            <span className={""}>{p.value.toString().includes("$") ? p.value : "$" + Number(p.value).toLocaleString(
                                                                "default", {
                                                                    currency: "usd", minimumFractionDigits: 2
                                                                }
                                                            )}</span>
                                                            :
                                                            <span className={"overflow-hidden overflow-ellipsis"}>{p.value.toString()}</span>
                                                }
                                            </div>
                                        )
                                    }
                                    {
                                        doc.parameters && (doc.parameters.length > 4 || doc.parameters.filter(p => p.type === "object").length) &&
                                        <div key={doc.id + " more_info"}
                                             className={"flex items-center gap-2 text-sm text-gray-600"}>
                                            <span>More Information</span>
                                            <span className={"material-symbols-rounded text-md"}>expand_more</span>
                                        </div>
                                    }
                                </div>
                            )
                        }
                    </div>
            }

        </div>


    </div>
}

export default AnalysisSideBar;