import React, {useEffect, useRef, useState} from "react";
import Input from "./Input";
import Notify from "./Notification";
import {contractUpload, uploadAnalysis} from "../api/dashboard";
import DropDown from "./DropDown";

function InvoiceUploadModal({getInvoices}) {
    const [status, setStatus] = useState(null);
    const [docTypes, setDocTypes] = useState({});
    const [files, setFiles] = useState([]);
    const filesAccepted = ["pdf",];

    const inputRef = useRef();
    const modalRef = useRef();

    const handleDialog = () => {
        if (modalRef.current.open) {
            inputRef.current.value = "";
            setFiles([]);
            modalRef.current.close();
        } else {
            if (status && status.loaded === status.total) setStatus(null);

            if (files.filter(f => !f.discard).length) modalRef.current.showModal(); else {
                inputRef.current.click();
            }
        }
    }

    const handleChange = (evt) => {
        if (status) return;
        let docs = [];

        if (evt.type === "drop") {
            evt.preventDefault();
            docs.push(...evt.dataTransfer.files)
        } else if (evt.target.files.length) {
            docs.push(...evt.target.files)
        }

        const types = {};
        console.log('a');
        for (let i = 0; i < docs.length; i++) {
            const name = docs[i].name.toLowerCase();
            if (name.includes("invoice")) {
                types[i] = 'iv';
            } else if (name.includes("agreement") || name.includes("contract")) {
                types[i] = 'ag';
            } else if (name.includes("delivery")) {
                types[i] = 'dd';
            } else if (name.includes("purchase")) {
                types[i] = 'po';
            } else if (name.includes("remittance")) {
                types[i] = 'ra';
            }
        }
        setDocTypes(types);

        if (docs.length) {
            console.log("Files accepted: ", docs.map(f => f.name).join(", "));
            setFiles(docs);
            modalRef.current.showModal();
        } else modalRef.current.close();
    }

    const uploadFile = () => {
        if (!files.length) return;

        const formData = new FormData();

        formData.append("types", JSON.stringify(docTypes));
        for (let file of files) {
            formData.append('files', file, file.name);
        }

        modalRef.current.close();

        uploadAnalysis(formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress: (ev) => setStatus(ev)
        }).then(([status, data]) => {
            if (status === 200) {
                Notify("Documents uploaded successfully", "success");
                inputRef.current.value = "";
                setFiles([]);
                getInvoices();
            }
        }).finally(() => setStatus(null));
    }

    useEffect(() => {
        if (files.filter(f => !f.discard).length === 0) modalRef.current.close();
    }, [files]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (files.length || e.target.reportValidity()) {
            uploadFile();
        }
    }

    const isFilesValid = files => files.length && files.every((f) => filesAccepted
        .includes(f.name.slice(f.name.lastIndexOf('.') + 1)) && f.size <= 5 * 1024 * 1024);


    return (<>
        <div id={"dropzone"}
             className={"flex p-4 py-6 flex-col items-center justify-center w-full bg-gray-100 hover:bg-gray-200 transition-all ease-in border border-dotted border-gray-400 rounded-lg cursor-pointer overflow-hidden"}
             onClick={handleDialog} onDrop={handleChange} onDragOver={(e) => e.preventDefault()} draggable={true}>
            {
                status ? <>
                    <span className={"material-symbols-rounded animate-spin"}>progress_activity</span>
                    <span className={"text-center text-sm text-gray-600 font-bold"}>
                    {status.loaded === status.total ? "Processing..." : `Uploading... ${status.loaded / status.total * 100}%`}
                </span>
                </> : <>
                    <span className={"material-symbols-rounded mso-fill text-3xl text-gray-600"}>cloud_upload</span>
                    <span className={"text-center text-sm text-gray-600"}>
                                        Click to browse or drop here to upload.<br/>
                                        Supported Formats: {filesAccepted.map(f => `.${f}`).join(", ")}. Maximum
                                        Individual File size: 5 MB
                    </span>
                </>
            }
        </div>
        <dialog ref={modalRef}
                className={"backdrop:backdrop-blur-[1px] hidden open:flex z-10 bg-white max-w-[480px] w-full p-8 outline-0 shadow rounded-lg flex-col items-center"}>
            <span className={"font-bold mb-4 text-lg"}>Upload File</span>
            <span className={"text-sm w-full"}>Click here to select the document type, and then click on the area below to upload the document.</span>

            <form onSubmit={handleSubmit} method={"POST"} className={"w-full mt-4"}>
                <input ref={inputRef} required={!files.length}
                       onChange={handleChange}
                       type={"file"} multiple={true} hidden={true} name={'analysis-docs'}
                       accept={filesAccepted.map(f => `.${f}`).join(", ")}
                />
                <ul className={"w-full min-h-80 max-h-40 mt-4 overflow-y-auto light-scroll"}>
                    {
                        files.map((file, index) =>
                            <li key={`${file.name}-${index}`}
                                className={"text-nowrap w-full px-4 py-3 bg-gray-100 rounded-md flex items-center justify-between gap-4"}>
                                <div className={"overflow-hidden flex flex-col"}>
                                    <span
                                        className={"text-sm font-medium overflow-hidden overflow-ellipsis"}>{file.name}</span>
                                    {
                                        !isFilesValid([file]) &&
                                        <span
                                            className={"text-xs font-medium overflow-hidden overflow-ellipsis text-red-600 mt-1"}>
                                            {file.size > 5 * 1024 * 1024 ? "File size is greater than 5MB is not allowed." : filesAccepted
                                                .includes(file.name.slice(file.name.lastIndexOf('.') + 1)) ? "Selected file format is not accepted." : ""}
                                        </span>
                                    }
                                </div>
                                <div className={"flex items-center gap-4"}>
                                    <DropDown pos={"t"} options={
                                        {
                                            "iv": "Invoice",
                                            "ag": "Agreement",
                                            "po": "Purchase Order",
                                            "dd": "Delivery Document",
                                            "ra": "Remittance Advice"
                                        }
                                    } Item={({docKey, name}) => {
                                        return <div
                                            onClick={() => {
                                                console.log(docKey, index);
                                                setDocTypes({...docTypes, [index]: docKey});
                                            }}
                                            className={"text-xs cursor-pointer bg-white px-4 py-2 border-b hover:bg-gray-100"}>
                                            {name}
                                        </div>
                                    }}>
                                        <div className={"px-3 py-2 bg-white border border-gray-50 rounded-lg text-xs flex items-center gap-2"}>
                                            <span>{
                                                ({
                                                    "iv": "Invoice",
                                                    "ag": "Agreement",
                                                    "po": "Purchase Order",
                                                    "dd": "Delivery Document",
                                                    "ra": "Remittance Advice"
                                                })[docTypes[index]]

                                            }</span>
                                            <span className={"material-symbols-rounded text-sm"}>expand_more</span>
                                        </div>
                                    </DropDown>

                                    <button className={"material-symbols-rounded text-xl"}
                                            onClick={() => setFiles(files.toSpliced(index, 1))}>close
                                    </button>
                                </div>
                            </li>)
                    }
                </ul>

                <div className={"flex w-full flex-col items-center gap-1 mt-10"}>
                    <button type={"submit"}
                            disabled={!isFilesValid(files)}
                            className={"w-full font-medium bg-accentBack disabled:bg-gray-200 p-3 text-white disabled:text-black rounded-md text-sm"}>Upload
                    </button>
                    <button type={"button"}
                            onClick={() => modalRef.current.close()}
                            className={"font-medium hover:bg-gray-100 w-full p-2 px-3 text-black rounded-md text-sm"}>Back
                    </button>
                </div>

            </form>
        </dialog>
    </>)
}

export default InvoiceUploadModal;