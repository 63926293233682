import {getTimePeriod, handleTip, reduceStats, unitizeAmount} from "./utils";
import React from "react";
import {Bar} from "react-chartjs-2";

function CashLeakage({data, term, postFilters}) {

    const activeTab = term === 0 ? "Year" : term === 1 ? "Quarter" : "Month"

    const [supplierReduced, supplierIndex] = reduceStats(data, "supplier", (record) => ((record.discounted_unit_price || record.unit_price) * 0.25) * record.quantity);

    const supplierLabels = Object.keys(supplierReduced).filter(x => supplierIndex[0] in supplierReduced[x]);
    const supplierCashLeakageDataset = [];
    supplierLabels.sort((a, b) => supplierReduced[b][supplierIndex[0]] - supplierReduced[a][supplierIndex[0]])

    supplierIndex.slice(0, 1).forEach((index, _in) => {
        supplierCashLeakageDataset.push({
            label: `In ${index} - Supplier Cash Leakage`,
            barThickness: "flex",
            backgroundColor: "#73e5f1",
            data: supplierLabels.map(x => supplierReduced[x][index]),
            stack: `stack-${index}`
        })
    });

    const [categoryReduced, categoryIndex] = reduceStats(data, "c1", (record) => ((record.discounted_unit_price || record.unit_price) * 0.25) * record.quantity);

    const categoryLabels = Object.keys(categoryReduced).filter(x => categoryIndex[0] in categoryReduced[x]);
    const categoryCashLeakageDataset = [];
    categoryLabels.sort((a, b) => categoryReduced[b][categoryIndex[0]] - categoryReduced[a][categoryIndex[0]])

    categoryIndex.slice(0, 1).forEach((index, _in) => {
        categoryCashLeakageDataset.push({
            label: `In ${index} - Category Leakage`,
            barThickness: "flex",
            backgroundColor: "#d6f173",
            data: categoryLabels.map(x => categoryReduced[x][index]),
            stack: `stack-${index}`
        })
    });


    return <div id={"cash-leak"} className={"flex flex-col card w-full"}>
        <div className={"whitespace-nowrap flex flex-col item-start justify-between w-full"}>

            <div className={"flex items-center w-full justify-between gap-2 relative"}>
                <span className={"font-bold text-gray-800 text-md"}>Cash Leakage</span>

                <div onMouseEnter={(e) => handleTip(e, "cash-leak")}
                     onMouseLeave={(e) => handleTip(e, "cash-leak")}
                     className={"show-tip"}>
                    <span className={"material-symbols-rounded text-md"}>info</span>
                    <div className={"tool-tip-cover"}>
                    <span
                        className={"p-2 block px-4 whitespace-normal text-sm w-[20rem]"}>{`The chart shows cash leakage across suppliers and level 2 category in ${activeTab} (${getTimePeriod(term)})`}</span>
                    </div>
                </div>
            </div>
            <span className={"text-xs text-gray-600"}>Latest {activeTab}</span>
        </div>
        <div className={"flex w-full items-center gap-4 flex-wrap flex-1"}>
            <div className={"flex-1 min-h-64 lg:max-h-96 lg:h-full overflow-hidden"}>
                <Bar data={{
                    labels: supplierLabels,
                    datasets: supplierCashLeakageDataset
                }} className={"w-full"} options={{
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label(tooltipItem) {
                                    return `${tooltipItem.dataset.label}: $${tooltipItem.formattedValue}`
                                }
                            }
                        }
                    },
                    maintainAspectRatio: false,
                    responsive: true, scales: {
                        y: {
                            min: postFilters.amount,
                            ticks: {
                                callback: (tickValue, index, ticks) => unitizeAmount(tickValue)
                            }
                        }
                    }
                }}/>
            </div>
            <div className={"flex-1 min-h-64 lg:max-h-96 lg:h-full overflow-hidden"}>
                <Bar data={{
                    labels: categoryLabels,
                    datasets: categoryCashLeakageDataset
                }} className={"w-full"} options={{
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label(tooltipItem) {
                                    return `${tooltipItem.dataset.label}: $${tooltipItem.formattedValue}`
                                }
                            }
                        }
                    },
                    maintainAspectRatio: false,
                    responsive: true, scales: {
                        y: {
                            min: postFilters.amount,
                            ticks: {
                                callback: (tickValue, index, ticks) => unitizeAmount(tickValue)
                            }
                        }
                    }
                }}/>
            </div>
        </div>
    </div>
}

export default CashLeakage;
