import {useEffect, useState} from "react";
import {Pie} from "react-chartjs-2";
import Markdown from "react-markdown";


const timeSince = (minutes) => {
    if (minutes <= 0)
        return "Just now";

    if (minutes < 60)
        return `${minutes} min${minutes !== 1 ? 's' : ''} ago`;

    const hour = Number.parseInt((minutes / 60).toString());
    return `${hour} hr${hour !== 1 ? 's' : ''} ago`;
}


function Chat({user, chat, setChat, context, apiHandler, className = ''}) {
    const [chatID, setChatID] = useState(null);
    const [conversation, setConversation] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [loader, setLoader] = useState(0);

    useEffect(() => {
        setLoader(0);
        apiHandler(true)
            .then(([status, data]) => {
                if (status === 200 && data) {
                    setTemplates(data.templates || []);
                    setChatID(data.chat_id || null);
                    setConversation([...conversation, !!data.templates.length ? {
                        from: "ai",
                        isTemplate: true,
                        content: "Hi there. How can I help you today? You can choose a prompt from below or type in your question.",
                        date: Date.now()
                    } : {from: "ai", content: "Hi there, how can I assist you?", date: Date.now()}]);
                    setLoader(1);
                } else {
                    setChat(!chat);
                    setLoader(-1);
                }
            });
    }, []);

    const handleSubmit = (e, template = null) => {
        e?.preventDefault();
        if (!chatID || (template && template.exec === null)) return;

        const messageID = Date.now();
        const message = {id: messageID, from: "human", template, content: e?.target.chat.value, date: Date.now()};
        const reply = {from: "ai", responseTo: messageID, response: generate(message), date: Date.now()};
        setConversation([...conversation, message, reply]);
        e?.target.reset();
    }

    const generate = async (message) => {
        const [status, data] = await apiHandler(false,
            {chat_id: chatID, message, context});
        return status === 200 ? data : null;
    }

    if (!chat) return;

    return <div id={"chat"}
                className={`card flex flex-col ${!chat && 'hidden'} fixed lg:sticky top-16 md:top-0 right-0 z-40 w-full md:max-w-96 lg:max-w-lg shadow drop-shadow lg:shadow-sm lg:drop-shadow-none lg:w-fit flex-1 h-full gap-2  p-0 pb-20 md:pb-0 ${className}`}>

        {/*  header  */}
        <div className={"flex items-center justify-between w-full p-4"}>
            <div className={"flex items-center gap-2"}>
                <img src={"/favicon.ico"} alt={"nimbey"}
                     className={"w-6 aspect-square rounded-md bg-black"}/>
                <span className={"font-bold"}>Nimbey AI</span>
            </div>
            <div className={"flex items-center gap-2"}>
                <button onClick={() => setChat(!chat)}
                        className={"px-1 border rounded-md material-symbols-rounded text-lg"}>chevron_right
                </button>
                <button className={"material-symbols-rounded text-lg"}>more_vert</button>
            </div>
        </div>

        {/*  conversation  */}
        <div className={"flex flex-col w-full flex-1 overflow-hidden"}>
            {
                chatID ?
                    <ul className={"flex flex-col w-full flex-1 overflow-hidden p-4 overflow-y-auto light-scroll gray-scroll"}>
                        <>
                            {
                                conversation.map((x, _) =>
                                    x.from === "human" ? <Human user={user} message={x}/> :
                                        <AI templates={templates} submit={handleSubmit} reply={x}/>
                                )
                            }
                        </>
                        <li id={"chatBottom"}/>
                    </ul>
                    : <div className={"flex-1 w-full flex items-center justify-center"}>
                        {
                            loader === 0 ?
                                <span
                                    className={"material-symbols-rounded text-lg animate-spin"}>progress_activity</span>
                                :
                                <span
                                    className={"text-sm"}>Chat is disabled!</span>
                        }
                    </div>
            }
        </div>

        {/*  input button  */}
        <div className={"flex flex-col w-full p-4"}>
            <form onSubmit={handleSubmit}
                  className={"flex items-stretch gap-4 rounded-xl m-2 text-xs border overflow-hidden"}>
                <input className={"p-4 pr-0 w-full outline-none rounded-xl placeholder:text-gray-600"}
                       name={"chat"} required={true} disabled={!chatID}
                       placeholder={!!templates.length ? 'or type what you want to start with' : "Hey Nimbey, can you please...."}/>
                <button type={"submit"} disabled={!chatID}
                        className={"px-4 hover:bg-gray-100 rounded-md material-symbols-rounded text-lg"}>send
                </button>
            </form>
            <span className={"flex w-full items-center justify-center text-xss text-gray-400"}>
                        Powered by Nimbey AI | Privacy Policy
            </span>
        </div>
    </div>
}


function AI({reply, templates, submit}) {
    const [since, setSince] = useState(
        ((new Date() - (reply ? reply.date : new Date())) / 6e4).toFixed(0)
    );
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        if (reply) {
            setLoading(true);
            document.getElementById("chatBottom").scrollIntoView(false);
            if (reply.response)
                reply.response
                    .then(res => {
                        setData(res);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            else {
                setData({text: reply.content});
                setLoading(false);
            }
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setSince(((new Date() - (reply ? reply.date : new Date())) / 6e4).toFixed(0));
        }, 60000);
        return () => {
            clearInterval(interval);
        }
    }, []);

    if (reply.isTemplate && templates && templates.length)
        return <li key={new Date().getTime()} className={"relative w-fit mb-8"}>
            <div className={"flex items-center justify-start relative"}>
                <div className={"p-4 rounded-xl bg-gray-100 text-xs font-medium pb-6"}>
                    <span>{reply.content}</span>
                    <ul className={"flex flex-col gap-2 mt-4"}>
                        {
                            templates.map(x =>
                                <li key={x.text}
                                    onClick={() => submit(null, x)}
                                    className={"cursor-pointer rounded-xl bg-white p-3 text-xs shadow"}>
                                    <span>{x.text}</span>
                                </li>
                            )
                        }
                    </ul>
                </div>
                <img src={"/favicon.ico"} alt={"nimbey"}
                     className={"w-6 absolute bg-black rounded-md -bottom-3 left-3"}/>
            </div>
            <div className={"flex items-center justify-end text-xss px-2"}>
                <span>{timeSince(since)}</span>

            </div>
        </li>

    return <li key={reply.date} className={"relative min-w-32 max-w-[80%] mb-6 w-fit"}>
        <div className={"flex items-center justify-end relative"}>
            <span className={"p-3 rounded-xl text-xs font-medium pb-4 w-full bg-gray-100"}>
                {
                    loading ?
                        <span className={"material-symbols-rounded animate-pulse"}>more_horiz</span>
                        :
                        data === null ?
                            <span>Apologies, there has been some error. Try again later.</span>
                            :
                            <div className={"flex flex-col w-full gap-2"}>
                                <Markdown>{data.text}</Markdown>
                                {
                                    data.canPlotGraph ?
                                        <>
                                            <Pie className={"p-2 w-[300px] h-[300px]"} data={{
                                                labels: data.graphData.map(x => x.label),
                                                datasets: [
                                                    {
                                                        data: data.graphData.map(x => x.value)
                                                    }
                                                ],
                                            }} options={{
                                                responsive: true,
                                                animation: false,
                                                plugins: {
                                                    legend: {
                                                        position: "bottom",
                                                    }
                                                }
                                            }}/>
                                        </>
                                        : <></>
                                }
                            </div>
                }
                        </span>
            <img src={"/favicon.ico"} alt={"nimbey"}
                 className={"w-6 absolute bg-black rounded-md -bottom-3 left-3"}/>
        </div>
        <div className={"flex items-center justify-end text-xss px-2"}>
            <span>{timeSince(since)}</span>
        </div>
    </li>
}

function Human({message, user}) {
    const [since, setSince] = useState(((new Date() - message.date) / 6e4).toFixed(0));
    useEffect(() => {
        const interval = setInterval(() => {
            setSince(((new Date() - message.date) / 6e4).toFixed(0));
        }, 60000);
        return () => {
            clearInterval(interval);
        }
    }, []);

    return <li key={message.id} className={"ml-auto w-fit relative mb-6 min-w-24"}>
        <div className={"flex items-center justify-end relative w-full"}>
            <span className={"p-3 rounded-xl text-xs font-medium pb-4 w-full bg-gray-100"}>
                {message.template ? message.template.text : message.content}
            </span>
            <img
                alt={"user"}
                src={user.image || ""}
                className={"w-6 bg-black rounded-full absolute -bottom-3 right-3"}/>
        </div>
        <div className={"text-xss px-2"}>
            <span>{timeSince(since)}</span>
        </div>
    </li>
}


export default Chat;