import React, {useEffect, useState} from "react";
import PreviousOrders from "../../components/PreviousOrders";
import FallbackImage from "../../static/img/fallback.png";
import Img from "../../components/Img";
import {Link} from "react-router-dom";
import {productInfo, searchProducts} from "../../api/dashboard";

export default function Home() {

    const [featuredProducts, setFeaturedProducts] = useState([]);

    // featured products
    useEffect(() => {
        const products = {};
        const software = async () => {
            products["IT, Software"] = {};
            const [status, data] = await searchProducts("software");
            if (status === 200) {
                data.products.slice(0,3).forEach((r) => products["IT, Software"][r.product_id] = {
                    img: r.product_image,
                    name: r.product_description,
                    id: r.product_id
                });
            }

        };
        const supplies = async () => {
            products["Facilities, R&D Supplies"] = {};
            for (let id of [980, 60, 54]) {
                const [status, data] = await productInfo(id);
                if (status === 200) {
                    products["Facilities, R&D Supplies"][id] = {
                        img: data.product_image,
                        name: data.product_description,
                        id: id
                    }
                }
            }
        };
        const safety = async () => {
            products["Part, Safety Systems"] = {};
            for (let id of [2121, 2125, 2129]) {
                const [status, data] = await productInfo(id);
                if (status === 200) {
                    products["Part, Safety Systems"][id] = {
                        img: data.product_image,
                        name: data.product_description,
                        id: id
                    }
                }
            }
        };

        Promise.all([software(), supplies(), safety()])
            .then(_ => {
                setFeaturedProducts(Object.keys(products).map((cat) => ({
                    category: cat,
                    products: Object.values(products[cat])
                })));
            })
    }, []);

    // const features = [
    //     {
    //         title: "IT, Software",
    //         icon: "sdk", color: "bg-blue-200", ps: "Operating System, Office Suite, Graphic Design...",
    //     },
    //     {
    //         title: "Facilities, R&D Supplies",
    //         icon: "scale", color: "bg-orange-100", ps: "Balances & Scales, Ball Mills, Beaker Heaters...",
    //     },
    //     {
    //         title: "Parts, Safety Systems",
    //         icon: "vacuum", color: "bg-emerald-100", ps: "Operating Mechanisms, Component Fittings, Braking Systems..."
    //     },
    //     {
    //         title: "New Item",
    //         icon: "box", color: "bg-gray-200", ps: "Request for new product.", url: "/staging-app/dashboard/orders/new-item",
    //     }
    // ]

    return <div className={"flex flex-col gap-6 w-full"}>
        {/* Featured Products */}
        <div className={"flex flex-col items-stretch md:flex-row w-full gap-2 md:flex-wrap h-full"}>
            {/*{*/}
            {/*    features.map((x, index) =>*/}
            {/*    <a href={x.url || `/staging-app/dashboard/search?query=${encodeURIComponent(x.title)}`} key={x.title}*/}
            {/*       className={`flex items-center justify-between min-w-52 ${"bg-white text-slate-800" || x.color} p-6 flex-1 h-28 card border-transparent relative`}>*/}
            {/*        <div className={"flex flex-col w-4/5 gap-1"}>*/}
            {/*            <div className={"flex items-center flex-wrap"}>*/}
            {/*               {x.title.split(",").map((w, index, ws) =>*/}
            {/*                   <span className={`font-bold leading-6 text-md ${(index !== ws.length - 1) && "leading-4 pr-1"}`}>{w}{(index !== ws.length - 1) && ","}</span>)}*/}
            {/*            </div>*/}
            {/*            <p className={"overflow-hidden pr-2 lg:text-xss xl:text-xs font-medium whitespace-nowrap overflow-ellipsis"}>{x.ps}</p>*/}
            {/*        </div>*/}
            {/*        <span*/}
            {/*            className={"font-bold material-symbols-rounded text-4xl absolute p-2 bottom-0 right-0 opacity-20"}>{x.icon}</span>*/}
            {/*    </a>)*/}
            {/*}*/}

            {/* Featured Products */}
            {
                featuredProducts.map((cat) =>
                    <div
                        className={"flex flex-col border h-auto xl:h-full w-full xl:w-1/4 p-4 gap-4 rounded-md flex-1 card"}
                        key={cat.category}>
                        <div className={"flex items-center w-full justify-between"}>
                                <span
                                    className={"capitalize text-sm md:text-xs xl:text-sm 2xl:text-md font-bold"}>{cat.category}</span>
                            <Link className={"font-semibold text-sm md:text-xs xl:text-xss text-violet-600"}
                                  to={`/staging-app/dashboard/search?query=${encodeURIComponent(cat.category)}`}>View
                                More</Link>
                        </div>
                        <div className={"gap-4 flex items-stretch justify-start w-full flex-1 overflow-hidden"}>
                            {
                                cat.products.map((prod, i) =>
                                    <Link to={`/staging-app/dashboard/products/${prod.id}`}
                                          key={`prod-${prod.name}-${i}`}
                                          className={"flex flex-1 overflow-hidden items-center justify-center"}>
                                        <Img src={prod.img || FallbackImage}
                                             className={"rounded-md w-full aspect-video object-contain object-center"}
                                             alt={prod.name}/>
                                    </Link>
                                )
                            }
                        </div>
                    </div>
                )
            }

            {/*New Item Card */}
            <div
                className={"flex-col border h-fit lg:h-full overflow-hidden w-full p-4 gap-4 rounded-md lg:flex-1 card flex items-center justify-center"}
                key={"newItem"}>
                <Link className={"h-full flex items-center justify-center"}
                      to={"/staging-app/dashboard/orders/new-item"}>
                    <span className={"material-symbols-rounded"}>arrow_outward</span>
                    <span className={"font-semibold text-sm text-accLinks"}>
                                    New Item
                                </span>
                </Link>
            </div>

        </div>

        {/*Order History*/}
        <div className={"flex flex-col"}>
            <PreviousOrders admin={false}/>
        </div>

    </div>

}