import React, {useEffect, useRef, useState} from "react";
import SelectInput from "./SelectInput";


function FormElement(props) {
    const [value, setValue] = useState(props.value.toString() || "");
    const [showPassword, setShowPassword] = useState(false);

    const error = props.errors[props.name];

    useEffect(() => {
        props.setValue(value);
    }, [value]);

    return <div className={"flex-col flex"}>
        <fieldset name={props.name}
                  className={`flex w-full items-center relative transition-all duration-100 ${props.className}`}>
            <legend
                className={`pointer-events-none absolute z-10 text-gray-600 leading-[0] flex items-center justify-center` +
                    ` ${props.value ? "text-xss top-0 px-2 left-4" : "text-xs p-3 px-6"} capitalize`}>{props.name}
                <hr className={props.value ? 'px-4 w-full border-2 border-white absolute -z-10' : 'hidden'}/>
            </legend>
            <input name={props.name} type={showPassword ? 'text' : props.type} autoComplete={props.autoComplete} value={value}
                   required={props.required} autoFocus={props.autoFocus}
                   min={props.min} max={props.max} maxLength={props.maxLength} minLength={props.minLength}
                   className={`p-3 px-6 border rounded-xl text-sm outline-accLinks w-full ${props.inputClass}`}
                   onChange={ev => setValue(ev.target.value)}
            />
            {
                props.type === "password" && props.toggleView &&
                <button type={"button"} onClick={() => setShowPassword(!showPassword)}
                        className={"material-symbols-rounded text-md absolute right-6"}>
                    {showPassword ? "visibility" : "visibility_off"}</button>
            }
        </fieldset>
        {error && <span className={"text-red-400 font-semibold text-xss p-1 mb-2"}>{error}</span>}
    </div>
}

export {FormElement};

export default function Input({
                                  name, type, label, id, errors, value, placeholder, onChange, required = false,
                                  endProp, maxLength, pattern, inpStyle = '', min, options, max, className = ""
                              }) {

    const ref = useRef();
    const labelRef = useRef();
    const [hasVal, setVal] = useState(Boolean(value));

    const handleChange = (e) => {
        setVal(e.target.value.toString().length !== 0);
        onChange(e);
    }

    useEffect(() => {
        setVal(Boolean(value));
    }, [value]);

    return (
        <>
            <div className={"flex flex-col items-start"} onClick={type === "select" ? undefined : (e) => {
                return type === "date" ? ref.current?.showPicker() : ref.current?.click()
            }}>
                <fieldset
                    className={`w-full relative rounded-md move-label border focus-within:border-2 ${errors ? "border-red-500" : "border-gray-400 focus-within:border-accLinks"} ${className}`}>
                    {
                        label && <legend
                            className={`${hasVal ? "relative" : "absolute"} leading-[0] invisible mx-3 text-xs px-1`}>{label}</legend>
                    }

                    <div className={"flex items-center w-full"}>
                        {
                            label && <label ref={labelRef}
                                            className={`absolute ${hasVal ? "-top-5 text-xs" : "top-0"} text-xs px-1 mx-3 my-3 text-gray-600 font-medium pointer-events-none`}
                                            htmlFor={id}>{label}</label>
                        }
                        {
                            type === "date" &&
                            <>
                                <input ref={ref} id={id} onChange={handleChange} defaultValue={value}
                                       min={min}
                                       className={`w-full px-4 py-3 rounded-md text-black text-md outline-none ${inpStyle}`}
                                       placeholder={placeholder}
                                       type={type} name={name} required={required}/>
                            </>
                        }
                        {
                            type === "select" &&
                            <SelectInput className={inpStyle} endProp={endProp}
                                         onChange={onChange} value={value} required={required}
                                         name={name} id={id} options={options}/>

                        }
                        {
                            type === "textarea" &&
                            <textarea ref={ref} id={id} onChange={handleChange} maxLength={maxLength}
                                      required={required}
                                      placeholder={placeholder}
                                      className={`w-full px-4 py-3 rounded-md text-black text-md outline-none ${inpStyle}`}>
                                    {value}
                                </textarea>

                        }
                        {
                            type === "number" &&
                            <input ref={ref} id={id} onChange={handleChange} defaultValue={value}
                                   min={min} max={max}
                                   className={`w-full px-4 py-3 rounded-md text-black text-md outline-none ${inpStyle}`}
                                   placeholder={placeholder}
                                   type={type} name={name} required={required}/>
                        }
                        {
                            ["email", "text", "password"].includes(type) &&
                            <input ref={ref} id={id} onChange={handleChange} defaultValue={value}
                                   maxLength={maxLength} pattern={pattern}
                                   className={`w-full px-4 py-3 rounded-md text-black text-md outline-none ${inpStyle}`}
                                   placeholder={placeholder}
                                   type={type} name={name} required={required}/>
                        }
                        {
                            type === "select" ? <></> : endProp
                        }
                    </div>
                </fieldset>
                {
                    errors && <span className={"block my-2 font-medium text-xs text-red-500"}>{errors}</span>
                }
            </div>
        </>
    )
}