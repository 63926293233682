import React, {useEffect, useRef, useState} from "react";

function DropDown({pos="b", children, options, Item}) {
    const [open, setOpen] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        if (open) {
            window.addEventListener("click", (e) => {
                if (!ref.current.contains(e.target)) {
                    setOpen(false);
                }
            });
        }
    }, [open]);

    return <div className={"flex relative"}>
        <div onClick={() => setOpen(!open)} ref={ref}>
            {children}
        </div>
        {
            open &&
            <div className={`flex flex-col rounded-lg overflow-hidden shadow-sm border absolute ${pos === "b" ? "bottom-0 right-0" : "top-0 left-0"} border-gray-200`}>
                {
                    Object.keys(options).map((k, index) => {
                        return <Item docKey={k} name={options[k]} _in={index}/>
                    })
                }
            </div>
        }
    </div>


}

export default DropDown;