import {Bar} from "react-chartjs-2";
import React from "react";
import {getTimePeriod, handleTip, reduceStats, unitizeAmount} from "./utils";

function BudgetSpendDept({data, budget, term, postFilters}) {

    const activeTab = term === 0 ? "Year" : term === 1 ? "Quarter" : "Month"
    const [reduced, index] = reduceStats(data, "department");

    const labels = Object.keys(reduced).filter(x => index[0] in reduced[x]);
    const datasets = [];
    labels.sort((a, b) => reduced[b][index[0]] - reduced[a][index[0]])

    index.slice(0, 1).forEach((index, _in) => {
        datasets.push({
            label: `In ${index} - Actual Spend`,
            barThickness: "flex",
            data: labels.map(x => reduced[x][index]),
            stack: `stack-${index}`
        })
    })

    datasets.push({
        label: "Assigned Budget",
        type: "scatter",
        data: labels.map(x => budget[x] || 0)
    });

    return <div id={"budget-spend-dept"}
                className={"flex flex-col card aspect-[0.8] lg:aspect-square flex-1 lg:max-h-96"}>
        <div className={"whitespace-nowrap flex w-full items-start justify-between"}>
            <div className={"flex flex-col item-start w-full"}>
                <div className={"flex items-center justify-between relative"}>
                    <span
                        className={"font-bold text-gray-800 text-md flex items-center gap-2"}>Budget By Department</span>

                    <div className={"flex items-center gap-4"}>
                        <div onMouseEnter={(e) => handleTip(e, "budget-spend-dept")}
                             onMouseLeave={(e) => handleTip(e, "budget-spend-dept")}
                             className={"show-tip"}>
                            <span className={"material-symbols-rounded text-md"}>info</span>
                            <div className={"tool-tip-cover"}>
                                <span className={"p-2 block px-4 whitespace-normal text-sm w-[20rem]"}>
                                    {`The bar graph shows spending across top level 2 categories in ${activeTab} (${getTimePeriod(term)})`}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"flex items-center gap-2"}>
                    <span className={"text-xs text-gray-600"}>Latest {activeTab}</span>
                </div>
            </div>

        </div>
        <div className={"flex-1 w-full overflow-hidden"}>
            <Bar id={"budget-spend-dept"} width={"100%"} className={"p-2"}
                 data={{
                     labels: labels,
                     datasets: datasets
                 }}
                 options={{
                     responsive: true,
                     maintainAspectRatio: false,
                     aspectRatio: 1.5,
                     scales: {
                         y: {
                             min: postFilters.amount,
                             ticks: {callback: (tickValue, index, ticks) => unitizeAmount(tickValue)}
                         },
                     }
                 }}
            />
        </div>
    </div>
}

export default BudgetSpendDept;