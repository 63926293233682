function FinancialInformation({ratios, setPopFinance, popFinance, filings, setTab, tab}) {
    if (!ratios) return;
    return <div className={`flex flex-col flex-1 bg-white card p-0 ${!popFinance && 'hidden'}`}>
            <div className={"flex items-center w-full justify-between p-4 pb-1 md:pb-0"}>
                <div className={"flex items-center gap-2"}>
                    <span className={"material-symbols-rounded text-md"}>drag_indicator</span>
                    <span
                        className={"text-sm font-semibold"}>Financial Statements</span>
                    <div className={`flex items-center ml-2 gap-2`}>
                        {
                            !!filings?.length && filings.slice(0, 2).map(x =>
                                <a href={x.url} target={"_blank"} rel={"noreferrer"} key={x.date}
                                   className={"flex items-center gap-1 rounded-full hover:bg-gray-200 bg-white"}>
                                    <span className={"material-symbols-rounded text-md"}>export_notes</span>
                                    <span className={"text-xs"}>{x.date.split("-")[0]}</span>
                                </a>
                            )
                        }
                    </div>

                </div>
                <div className={"flex gap-2 items-center"}>
                    {popFinance ?
                        <button onClick={() => {
                            setPopFinance(!popFinance); setTab(!tab);
                        }}
                                className={"material-symbols-rounded text-md"}>collapse_content</button>
                        :
                        <button onClick={() => {
                            setPopFinance(!popFinance); setTab(!tab);
                        }}
                                className={"material-symbols-rounded text-md"}>expand_content</button>}
                </div>
            </div>
            <div className={"flex flex-wrap w-full gap-2 p-2 md:p-4 md:pt-2"}>
                {
                    Object.entries(ratios).map(([cat, sub]) =>
                        Object.values(sub).map(x => Object.values(x)).reduce((prev, curr) => prev + curr.length, 0) ?
                            <div key={cat}
                                 className={"flex flex-col items-center border rounded-md overflow-hidden gap-2 flex-grow"}>
                                    <span
                                        className={"flex whitespace-nowrap overflow-ellipsis p-2 px-4 border-b text-xs font-semibold bg-gray-100 w-full"}>{cat}</span>
                                <div className={"flex items-stretch flex-wrap w-full h-full gap-2"}>
                                    {
                                        Object.entries(sub).map(([k, v]) =>
                                            Object.values(v).length ?
                                                <div key={k}
                                                     className={"flex flex-col items-start p-3 gap-2 rounded-md min-w-40 flex-grow"}>
                                                    <div
                                                        className={"flex flex-1 items-center justify-center gap-2 w-full"}>
                                                                <span
                                                                    className={"text-xs text-gray-600 font-semibold"}>{k}</span>
                                                    </div>
                                                    <div
                                                        className={"flex gap-2 text-sm items-center justify-center w-full"}>
                                                        {
                                                            Object.values(v).slice(0, 2).map((x, i) =>
                                                                i === 0 ?
                                                                    <span className={"text-green-400 text-md"}>
                                                                    {x['value']}
                                                                </span> :
                                                                    <span className={"text-gray-400 text-sm"}>
                                                                    {x['value']}
                                                                </span>
                                                            )
                                                        }
                                                    </div>
                                                </div> : <></>
                                        )
                                    }
                                </div>
                            </div> : <></>
                    )
                }
            </div>
        </div>
}

export default FinancialInformation;