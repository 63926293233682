import {useOutletContext} from "react-router-dom";
import React, {useEffect, useState} from "react";
import InvoiceUploadModal from "../../../components/InvoiceUploadModal";
import {adminInvoices, deleteAnalysis, invoiceStatus} from "../../../api/dashboard";
import Notify from "../../../components/Notification";
import AnalysisRecord from "../../../components/AnalysisRecord";
import AnalysisSideBar from "../../../components/AnalysisSideBar";

function AdminInvoiceAnalysis() {
    const {chat} = useOutletContext();
    const [invoices, setInvoices] = useState([]);
    const [statusUpdates, setStatusUpdates] = useState({});
    const [loader, setLoader] = useState(false);
    const [describeAnalysis, setDescribeAnalysis] = useState(null);

    const getInvoices = () => {
        setLoader(true);
        adminInvoices()
            .then(([status, data]) => {
                if (status === 200) {
                    setInvoices(data);
                    setStatusUpdates({});
                } else {
                    Notify("Couldn't fetch invoices", "err")
                }
            })
            .finally(() => setLoader(false));
    }

    useEffect(getInvoices, []);

    useEffect(() => {
        if (invoices && invoices.length) {
            const interval = setInterval(() => {
                invoiceStatus({ids: invoices.filter(x => x.status !== "Active").map((inv) => inv.id)})
                    .then(([status, data]) => {
                        if (status === 200) {
                            Object.values(data).forEach((x) => {
                                if (x.status === "Active") {
                                    getInvoices();
                                }
                            })
                            setStatusUpdates(data);
                        }
                    });
            }, 10000);
            return () => {
                clearInterval(interval);
            }
        }
    }, [invoices]);

    const handleDelete = (id) => {
        deleteAnalysis(id).then(([status, data]) => {
            if (status === 200) {
                getInvoices();
            }
        });
    }

    if (loader)
        return <div className={"flex w-full items-center justify-center flex-1"}>
            <span className={"w-full text-center block"}>
                <span className={"material-symbols-rounded animate-spin"}>progress_activity</span>
            </span>
        </div>

    return <div className={"flex flex-col items-start w-full gap-4 rounded-md relative flex-1 px-2"}>
        <div className={"py-2"}>
            <span className={"flex text-xl gap-1 w-full"}><b>Analysis</b></span>
        </div>
        <div className={"card flex flex-col w-full h-full"}>
            <div className={"w-full"}>
                <InvoiceUploadModal getInvoices={getInvoices}/>
            </div>
            <div className={"w-full flex flex-col"}>
                <div key={"header-0"}
                     className={`rounded-md mt-4 w-full hidden ${chat ? "xl:flex" : "lg:flex"} items-center justify-between bg-gray-100 border mb-4 text-xs text-gray-600 font-semibold`}>
                    <div className={"flex items-center flex-[4] p-4"}>
                        <span className={"flex-[1.5]"}>Invoice Number</span>
                        <span className={"flex-1"}>Uploaded on</span>
                        <span className={"flex-1"}>Recoverable<br/>Amount</span>
                        <span className={"flex-1"}>Priority</span>
                        <span className={"flex-1"}>Incorrect/Correct<br/>Charge</span>
                    </div>
                    <div className={"flex-[0.5] flex items-center p-4"}>
                    </div>
                </div>
                <div className={`flex flex-col w-full mb-4 h-full py-4 ${chat ? "xl:py-0" : "lg:py-0"} gap-4`}>
                    {
                        !!invoices.length ?
                            invoices.sort((a, b) => a.created_at > b.created_at ? -1 : 1).map(invoice =>
                                <AnalysisRecord statusUpdates={statusUpdates}
                                                handleDelete={handleDelete}
                                                record={invoice}
                                                setDescribeAnalysis={setDescribeAnalysis}/>
                            )
                            :
                            <div className={"w-full flex items-center justify-center p-4 text-sm text-gray-600"}>
                                <span>No invoices available</span>
                            </div>
                    }
                </div>
            </div>
            {
                describeAnalysis &&
                <AnalysisSideBar record_id={describeAnalysis} setDescribeAnalysis={setDescribeAnalysis}/>
            }
        </div>
    </div>
}

export default AdminInvoiceAnalysis;