import {ComparisonArrow, getTimePeriod, handleTip, humanizeTime} from "./utils";
import React from "react";

function AverageApproval({data, term}) {
    const activeTab = term === 0 ? "Year" : term === 1 ? "Quarter" : "Month"

    const avgApprovalTime = Object.entries(data).reverse().slice(0, 2).map(([key, value]) =>
        Number.parseFloat((value.reduce((prev, curr) =>
                prev + (curr.approved_on ? Math.abs(new Date(curr.approved_on) - new Date(curr.order_date)) : 0), 0)
            / value.length).toFixed(2)));

    if (avgApprovalTime)
        return <div id={"avgApp"} className={"col-span-2 card flex flex-1"}>
            <div
                className={"h-fit whitespace-nowrap flex flex-1 items-center justify-between"}>
                <div className={"flex flex-col item-start"}>
                    <div className={"flex items-center justify-between gap-2 relative"}>

                        <span className={"font-bold text-gray-800 text-md"}>Average Approval Time</span>

                        <div onMouseEnter={(e) => handleTip(e, "avgApp")}
                             onMouseLeave={(e) => handleTip(e, "avgApp")}
                             className={"show-tip"}>
                            <span className={"material-symbols-rounded text-md"}>info</span>
                            <div
                                className={"tool-tip-cover"}>
                            <span
                                className={"p-2 block px-4 whitespace-normal text-sm w-[20rem]"}>{`The average time required by the approver to take action over ${activeTab} (${getTimePeriod(activeTab)})`}</span>
                            </div>
                        </div>
                    </div>

                    <span
                        className={"text-xs text-gray-600"}>This {activeTab}</span>
                </div>
                <div className={"flex items-end gap-1"}>
                    <span
                        className={"font-extrabold text-2xl"}>{humanizeTime(avgApprovalTime[0] || 0)}</span>
                    <ComparisonArrow one={avgApprovalTime[0] / 1000}
                                     suffix={" seconds"} prefix={""}
                                     two={avgApprovalTime[1] / 1000}/>
                </div>
            </div>
        </div>
}

export default AverageApproval;