import 'chart.js/auto';
import React, {useEffect, useState} from "react";
import TopSuppliers from "./graphs/TopSuppliers";
import CategoryLevelTwo from "./graphs/CategoryLevelTwo";
import TopSpendByDepartments from "./graphs/TopSpendByDepartments";
import CategoryLevelOne from "./graphs/CategoryLevelOne";
import CategoryLevelThree from "./graphs/CategoryLevelThree";
import Dials from "./graphs/Dials";
import CashLeakage from "./graphs/CashLeakage";
import BudgetSpendLoc from "./graphs/BudgetSpendLoc";
import BudgetSpendDept from "./graphs/BudgetSpendDept";
import {filterStats, statsByTerm} from "./graphs/utils";
import AverageApproval from "./graphs/AverageApproval";
import AverageDelivery from "./graphs/AverageDelivery";
import SupplierFamilyTree from "./graphs/SupplierFamilyTree";
import TotalSpend from "./graphs/TotalSpend";
import TopSavings from "./graphs/TopSavings";
import {adminStats} from "../../../api/dashboard";
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";


function AdminDashboard() {
    const [stats, setStats] = useState([]);
    const [budget, setBudget] = useState({locations: null, departments: null});
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({});
    const [postFilters, setPostFilters] = useState({});
    const [term, setTerm] = useState(0);
    localStorage.setItem('admin.dashboard.activeTab', term.toString());

    useEffect(() => {
        setLoading(true);
        adminStats()
            .then(([status, data]) => {
                if (status === 200) {
                    setStats(data.stats);
                    setBudget(data.budget);
                } else {
                    setTerm(Number.parseInt(localStorage.getItem("admin.dashboard.activeTab")) || 0);
                }
            })
            .finally(() => setLoading(false));
    }, []);


    if (loading) return <AdminDashboardLayout term={term} setTerm={setTerm}>
        <div className={"flex items-center justify-center flex-col"}>
            <span className={"material-symbols-rounded animate-spin"}>progress_activity</span>
            <span>Loading Dashboard...</span>
        </div>
    </AdminDashboardLayout>

    if (!stats || !stats.length)
        return <AdminDashboardLayout term={term} setTerm={setTerm}>
            <div className={" flex w-full flex-1 flex-col items-center justify-center"}>
                <span className={"text-bold text-lg"}>Apologies, Something went wrong!</span>
            </div>
        </AdminDashboardLayout>

    const data = statsByTerm(filterStats(stats, filters), term);
    return <AdminDashboardLayout term={term} setTerm={setTerm} setFilters={setFilters} filters={filters}
                                 postFilters={postFilters}
                                 setPostFilters={setPostFilters} data={data}>
        <div className={"flex flex-col items-start justify-start flex-1 w-full gap-2 lg:gap-4 mt-2"}>
            <div className={"flex flex-col xl:flex-row flex-wrap items-stretch w-full gap-2 lg:gap-4 h-full"}>

                {/*top supplier graph */}
                <TopSuppliers data={data} term={term} postFilters={postFilters} setFilters={setFilters}
                              filters={filters}/>

                {/* top spending graph */}
                <TotalSpend data={data} term={term} postFilters={postFilters}/>

                {/*cat one graph*/}
                <CategoryLevelOne data={data} term={term} postFilters={postFilters} setFilters={setFilters}
                                  filters={filters}/>

            </div>
            <div className={"flex flex-col xl:flex-row flex-wrap items-stretch w-full gap-2 lg:gap-4 h-full"}>

                {/*top categories graph */}
                <CategoryLevelTwo data={data} term={term} postFilters={postFilters} setFilters={setFilters}
                                  filters={filters}/>

                {/* cat three graph */}
                <CategoryLevelThree data={data} term={term} postFilters={postFilters} setFilters={setFilters}
                                    filters={filters}/>

                {/* product contract and order request type */}
                <Dials data={data} term={term} postFilters={postFilters} setFilters={setFilters} filters={filters}/>
            </div>
            <div className={"flex flex-col xl:flex-row flex-wrap items-stretch w-full gap-2 lg:gap-4 h-full"}>
                {/**/}
                <CashLeakage data={data} term={term} postFilters={postFilters}/>
            </div>
            <div className={"flex flex-col xl:flex-row flex-wrap items-stretch w-full gap-2 lg:gap-4 h-full"}>
                {/**/}
                <BudgetSpendLoc data={data} budget={budget.locations} setFilters={setFilters} filters={filters}
                                term={term} postFilters={postFilters}/>

                {/**/}
                <BudgetSpendDept data={data} budget={budget.departments} setFilters={setFilters} filters={filters}
                                 term={term} postFilters={postFilters}/>

                {/* spend by department graph */}
                <TopSpendByDepartments data={data} term={term} postFilters={postFilters} setFilters={setFilters}
                                       filters={filters}/>
            </div>
            <div className={"flex flex-col xl:flex-row flex-wrap items-stretch w-full gap-2 lg:gap-4 h-full"}>
                {/* savings graph */}
                <TopSavings data={data} term={term} postFilters={postFilters}/>

                {/**/}
                <SupplierFamilyTree filters={filters} setFilters={setFilters} data={data} term={term}
                                    postFilters={postFilters}/>
            </div>
            <div className={"flex flex-col xl:flex-row flex-wrap items-stretch w-full gap-2 lg:gap-4 h-full"}>
                {/* average approval time */}
                <AverageApproval data={data} term={term} postFilters={postFilters}/>

                {/* average delivery time */}
                <AverageDelivery data={data} term={term} postFilters={postFilters}/>
            </div>

        </div>
    </AdminDashboardLayout>
}

export default AdminDashboard;