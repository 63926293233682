import React, {useEffect, useState} from "react";
import Sorter from "../../components/Sorter";
import {Link, useLocation, useSearchParams} from "react-router-dom";
import Img from "../../components/Img";
import FallbackImage from "../../static/img/fallback.png";
import {humanizePrice} from "./admin/graphs/utils";
import {searchProducts} from "../../api/dashboard";


const SearchProduct = ({prod}) => {
    const {id, img, name, sub, sub2} = {
        id: prod.product_id,
        img: prod.product_image,
        name: prod.product_name,
        sub: prod.unit_price,
        sub2: new Date(Date.now() + 500000000).toDateString(),
    };
    return <Link to={`/staging-app/dashboard/products/${id}`} key={id}
                 className={"flex items-center relative border-b w-full md:px-4 py-3 card"}>
        <Img src={img || FallbackImage} alt={name} className={"aspect-[1.5] h-[75px] object-contain"}/>
        <div className={"flex flex-col h-full p-2 px-4 w-full gap-1"}>
            <span className={"text-xs md:text-xs font-medium"}>{name}</span>
            <span className={"text-accLinks text-sm font-bold"}>$ {humanizePrice(Number.parseFloat(sub))}</span>
            <div className={"flex gap-1 items-center whitespace-nowrap text-gray-600 text-xss"}>
                <span className={"material-symbols-rounded text-xs"}>local_shipping</span>
                Get by
                <span className={"font-semibold"}>{sub2}</span>
            </div>
        </div>
        <div className={"flex items-center gap-4"}>
            <button className={"material-symbols-rounded text-sm"}>
                more_vert
            </button>
        </div>
    </Link>
}

export default function Products() {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const [payload, setPayload] = useState({});
    const [loader, setLoader] = useState(true);

    const [sort, setSort] = useState(null);
    const [sortOpen, setSortOpen] = useState(false);

    const columns = {
        unit_price: {id: 'unit_price', name: "Amount", value: (p) => p.unit_price},
        product_name: {id: 'product_name', name: "Name", value: (p) => p.product_name},
    }
    const isSearchPage = (location.pathname || window.location.pathname).startsWith("/staging-app/dashboard/search");

    useEffect(() => {
        if (sort) setSearchParams(p => {
            p.set("sort", sort.id);
            p.set("desc", sort.desc);
            p.delete("index");
            return p;
        }, {preventScrollReset: true})
    }, [sort])

    useEffect(() => {
        setLoader(true);
        searchProducts("", searchParams)
            .then(([status, data]) => {
            if (status === 200) {
                setLoader(false);
                if (data.index === 0) setPayload(data);
                else setPayload({...data, products: [...(payload.products || []), ...data.products]})
            }
        })
            .finally(() => setLoader(false));
    }, [searchParams]);


    useEffect(() => {
        const listener = (e) => {
            if (e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight) {
                if (payload.index + payload.length < payload.total)
                    setSearchParams(p => {
                        p.set("index", payload.index + payload.length);
                        return p;
                    }, {preventScrollReset: true});
            }
        }
        const container = document.getElementById('outlet');
        if (payload.index + payload.length < payload.total) {
            container.addEventListener('scroll', listener);
        } else {
            container.removeEventListener('scroll', listener);
        }

        return () => {
            container.removeEventListener('scroll', listener);
        }
    }, [payload]);


    const Layout = ({children}) => <div className={"flex flex-col w-full h-full gap-4 px-2"}>
        <div className={"flex items-center justify-between p-2 w-full"}>
            {
                isSearchPage ? <span className={"px-2 lg:px-0 text-lg"}>Search Results for <br
                        className={"lg:hidden"}/><b className={"font-bold"}>"{searchParams.get('query')}"</b> <b
                        className={"font-bold"}>({payload.total})</b> </span>
                    :
                    <b className={"flex text-xl gap-1 w-full"}>Products</b>
            }

            {!!payload.length &&
                <Sorter sort={sort} setSort={setSort} setSortOpen={setSortOpen} sortOpen={sortOpen} columns={columns}/>}
        </div>
        {children}
    </div>

    const products = [...(payload.products || [])];
    if (sort)
        products.sort((a, b) => (sort.desc ? -1 : 1) * (columns[sort.id].value(b) > columns[sort.id].value(a) ? -1 : 1))

    return <Layout>
        <div className={"flex flex-col items-stretch w-full gap-1 pb-24"}>
            {
                products.length ?
                    <>
                        {products.map((r, i) => <SearchProduct prod={r}/>)}
                        {
                            payload.index + payload.length < payload.total &&
                            <div className={"flex flex-col items-center w-full gap-1 p-8"}>
                                <span className={"material-symbols-rounded animate-spin"}>progress_activity</span>
                            </div>
                        }
                    </>
                    : loader ? <div className={"flex flex-col items-center w-full gap-1 p-8"}>
                            <span className={"material-symbols-rounded animate-spin"}>progress_activity</span>
                        </div> :
                        <span className={"font-semibold"}>No results</span>
            }
        </div>
    </Layout>

}