import React, {useEffect, useRef, useState} from "react";

function SelectInput({className, name, id, options, onChange, required, value, endProp=<></>}) {
    const [open, setOpen] = useState(false);
    const [v, setV] = useState();
    const ref = useRef();

    const setValue = (value) => {
        document.getElementById(id).value = value;
        document.getElementById(id).dispatchEvent(new Event("input", {bubbles: true}));
        document.getElementById(`opt-${id}`).classList.toggle("hidden");
        setV(value);
    }

    useEffect(() => {
        setV(value)
    }, [value]);

    useEffect(() => {
        if (open) {
            const handler = (e) => {
                if (!document.getElementById(`select-inp-${id}`).contains(e.target)) {
                    setOpen(false);
                }
            }
            window.addEventListener('click', handler);
            return () => {
                window.removeEventListener("click", handler);
            }
        }
    }, [open]);

    return (
        <>
            <div id={`select-inp-${id}`} className={"w-full"} onClick={() => setOpen(!open)}>
                {/*<input required={required} ref={ref} type={"hidden"} onInput={onChange} name={name} id={id}/>*/}
                <select ref={ref} name={name} id={id} onInput={onChange} required={required} defaultValue={value}
                        className={`opacity-0 pointer-events-none absolute w-full px-4 py-3 -z-10 ${className || ""}`}>
                    <option value={""}>Choose {name}</option>
                    {
                        options.map(opt =>
                            <option key={opt.id.toString()} value={opt.id}>{opt.name}</option>
                        )
                    }
                </select>
                <button type={"button"} className={`px-4 py-3 z-10 bg-white rounded-md w-full ${className || ""}`}>
                    <div className={"flex items-center w-full justify-between gap-2"}>
                        <span className={"block"}>
                            {
                                Boolean(v) && options.length ?
                                    options.find(o => o.id.toString() === v.toString())?.name
                                    :
                                    `Choose ${name}`
                            }
                        </span>
                        { endProp }
                    </div>
                </button>
                {
                    open &&
                    <ul id={`opt-${id}`}
                        className={`absolute z-10 bg-white rounded-md border shadow w-full overflow-y-auto max-h-[200px]`}
                    >
                        {
                            options.map((opt) =>
                                <li onClick={() => setValue(opt.id)}
                                    tabIndex={0} key={`opt-${opt.id}-${name}`}
                                    className={"cursor-pointer hover:bg-gray-100 px-4 py-3"}>{opt.name}</li>
                            )
                        }
                    </ul>
                }
            </div>
        </>
    )
}

export default SelectInput;