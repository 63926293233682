function FinancialStats({tab, filings, popFinance, setPopFinance, setTab, ratios, secLoading}) {
    return <div className={`flex flex-col flex-1 bg-white card p-0 ${!!!tab && 'md:hidden'} ${popFinance && 'hidden md:flex'}`}>
        <div className={"flex items-center w-full justify-between p-4 pb-1 md:pb-0"}>
            <div className={"flex items-center gap-2"}>
                <span className={"material-symbols-rounded text-md"}>drag_indicator</span>
                <span
                    className={"text-sm font-semibold"}>Financial Statements</span>
                <div className={`flex items-center ${!tab && 'hidden'} ml-2 gap-2`}>
                    {
                        !!filings?.length && filings.slice(0, 2).map(x =>
                            <a href={x.url} target={"_blank"} rel={"noreferrer"} key={x.date}
                               className={"flex items-center gap-1 rounded-full hover:bg-gray-200 bg-white"}>
                                <span className={"material-symbols-rounded text-md"}>export_notes</span>
                                <span className={"text-xs"}>{x.date.split("-")[0]}</span>
                            </a>
                        )
                    }
                </div>

            </div>
            <div className={"flex gap-2 items-center"}>
                {popFinance ?
                    <button onClick={() => {
                        setPopFinance(!popFinance);
                        setTab(!tab);
                    }} className={"material-symbols-rounded text-md"}>collapse_content</button>
                    :
                    <button onClick={() => {
                        setPopFinance(!popFinance);
                        setTab(!tab);
                    }} className={"material-symbols-rounded text-md"}>expand_content</button>}
            </div>
        </div>
        <div className={"flex flex-col items-center justify-center flex-1 md:flex-row w-full gap-2 p-2"}>
            {
                !!filings?.length &&
                <button onClick={() => setTab(false)}
                        className={"material-symbols-rounded hidden md:block"}>keyboard_arrow_left</button>
            }
            {
                secLoading &&
                <div className={`flex items-center justify-center w-full ${!tab && 'hidden'}`}>
                    <span className={"material-symbols-rounded animate-spin text-lg"}>progress_activity</span>
                </div>
            }
            {
                !secLoading && ratios &&
                Object.values(ratios).slice(0, 3).map((cats) => Object.entries(cats || {}).slice(0, 1).map(([k, v]) =>
                    <div key={k}
                         className={`flex flex-col items-stretch justify-center p-3 w-full md:flex-1 gap-2 rounded-md bg-gray-50 ${!tab && 'hidden'}`}>
                        <div className={"flex items-center justify-center gap-2 w-full"}>
                            <span className={"text-sm text-gray-600 font-semibold"}>{k}</span>
                        </div>
                        <div className={"flex gap-2 items-center justify-center w-full"}>
                            {
                                Object.values(v || {}).slice(0, 2).map((x, i) =>
                                    i === 0 ?
                                        <span
                                            className={"text-green-400 text-md"}>{x['value']}</span> :
                                        <span
                                            className={"text-gray-400 text-sm"}>{x['value']}</span>
                                )
                            }
                        </div>
                    </div>))
            }
            {
                !secLoading && !ratios && filings &&
                Object.values(filings).map((v) => <a href={v.url} target={"_blank"} rel={"noreferrer"}
                                                     key={v.date}
                                                     className={`flex flex-col items-center justify-center flex-1 p-3 w-[33%] gap-2 rounded-lg bg-gray-50 hover:bg-gray-100 ${!tab && 'hidden'}`}>
                    <span className={"text-xs text-gray-600 font-bold"}>{v.date}</span>
                    <span className={"material-symbols-rounded text-sm"}>open_in_new</span>
                </a>)
            }
            {
                !!filings?.length &&
                <button onClick={() => setTab(true)}
                        className={`material-symbols-rounded hidden md:block ${tab ? 'text-gray-400': 'text-black'}`}>keyboard_arrow_right</button>
            }
        </div>
    </div>
}

export default FinancialStats;