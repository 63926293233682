import request, {products, admin, orders, utils, api} from "./init";

function searchProducts(q, paramsOverride = null) {
    return request(`${products}/search?${paramsOverride ? paramsOverride : `query=${q}`}`, null, "GET");
}

function productInfo(product_id) {
    return request(`${products}/product/${product_id}`, null, "GET");
}

function previousOrders(forAdmin) {
    return request(`${forAdmin ? admin : orders}/previous-orders`, null, "GET");
}

function getLocations() {
    return request(`${utils}/locations`, null, "GET");
}

function getCategories() {
    return request(`${utils}/categories`, null, "GET");
}

function placeOrder(payload) {
    return request(`${orders}/place-order`, payload);
}

function newItemOrder(payload) {
    return request(`${orders}/new-item`, payload);
}

function orderDetails(order_id) {
    return request(`${orders}/order-details?order_id=${order_id}`, null, "GET");
}

function getOrderRequest(cart_id) {
    return request(`${orders}/approve?cart_id=${cart_id}`, null, "GET");
}

function approveOrder(payload) {
    return request(`${orders}/approve`, payload, "POST");
}

function adminStats() {
    return request(`${admin}/stats`, null, "GET");
}

function adminContracts() {
    return request(`${admin}/contracts`, null, "GET");
}

function groupContracts(payload) {
    return request(`${admin}/contracts/group`, payload);
}

function disableContracts(payload) {
    return request(`${admin}/contracts/disable`, payload, "PATCH");
}

function deleteContracts(payload) {
    return request(`${admin}/contracts/delete`, payload, "PATCH");
}

function contractStatus(payload) {
    return request(`${admin}/contracts/status`, payload);
}

function contractUpload(payload, config) {
    return request(`${admin}/contracts/upload`, payload, "POST", config);
}

function contractDetails(contract_id) {
    return request(`${admin}/contracts/details?contract_id=${contract_id}`, null, "GET");
}

function nimbotChat(init = false, payload = null) {
    return request(`${api}/nimbey/chat`, payload, init ? "GET" : "POST");
}

function getCart() {
    return request(`${api}/orders/cart`, null, "GET");
}

function updateCart(payload) {
    return request(`${api}/orders/cart`, payload, "PATCH");
}

function submitCart(payload) {
    return request(`${api}/orders/cart`, payload, "POST");
}

function adminInvoices() {
    return request(`${admin}/invoice-analysis`, null, "GET");
}

function invoiceStatus(payload) {
    return request(`${admin}/invoice-analysis/status`, payload, "POST");
}

function uploadAnalysis(payload) {
    return request(`${admin}/invoice-analysis`, payload, "POST");
}

function analysisDetails(invoice_id) {
    return request(`${admin}/invoice-analysis/details?invoice_id=${invoice_id}`, null, "GET");
}

function deleteAnalysis(key) {
    return request(`${admin}/invoice-analysis/delete?invoice_id=${key}`, null, "GET");
}


export {
    searchProducts,
    productInfo,
    previousOrders,
    getLocations,
    getCategories,
    placeOrder,
    newItemOrder,
    orderDetails,
    getOrderRequest,
    approveOrder,
    adminStats,
    adminContracts,
    groupContracts,
    disableContracts,
    deleteContracts,
    contractStatus,
    contractUpload,
    contractDetails,
    nimbotChat,
    getCart,
    updateCart,
    submitCart,
    adminInvoices,
    invoiceStatus,
    uploadAnalysis,
    analysisDetails,
    deleteAnalysis
};