import {Bar} from "react-chartjs-2";
import {useEffect, useRef} from "react";

function TopSupplier({data, supplierURI, setSupplierURI, chat}) {

    const processData = (ent) => {
        const temp = {};
        for (let i of ent.shipments) {
            temp[i.supplier_uri] = {
                supplier_uri: (temp[i.supplier_uri]?.supplier_uri || i.supplier_uri),
                name: (temp[i.supplier_uri]?.name || i.name),
                country: (temp[i.supplier_uri]?.country || i.country),
                shipments: ((temp[i.supplier_uri]?.shipments || 0) + i.shipments)
            };
        }

        const data = Object.values(temp).toSorted((a, b) => a.shipments > b.shipments ? -1 : 1);

        const others = data.slice(9,)
            .reduce((prev, curr) =>
                ({
                    name: "Others",
                    shipments: curr.shipments + prev.shipments
                }), {
                name: "Others",
                shipments: 0
            })

        data.splice(9, data.slice(9,).length);
        data.sort((a, b) => a.name > b.name ? -1 : 1);
        data.sort((a, b) => a.country > b.country ? -1 : 1);
        data.sort((a, b) => a.shipments > b.shipments ? -1 : 1);

        data.push(others);

        return data;
    }
    const processedData = processData(data);
    const total = processedData.reduce((prev, curr) => prev + curr.shipments, 0);
    const labels = processedData.map(x => x.name)
    const chart = useRef();

    useEffect(() => {
        chart.current?.chartInstance?.update();
    }, [supplierURI, chat, data]);

    return <div className={`flex flex-col md:items-stretch w-full min-w-96 h-full max-h-96 card p-0 xl:flex-1` }>
        <div className={"flex items-center gap-2 p-4 pb-0"}>
            <span className={"material-symbols-rounded text-md"}>drag_indicator</span>
            <span className={"text-sm font-semibold"}>Top Supplier</span>
        </div>
        <div className={"p-4 w-full overflow-hidden flex-1"}>
            <Bar ref={chart} className={"w-full h-full"} id={"topSuppliers"} data={{
                labels: labels,
                datasets: [{
                    axis: 'y',
                    data: processedData.map(x => (x.shipments * 100 / total).toFixed(1)),
                    fill: true,
                    borderWidth: 1,
                    borderColor: "rgba(30, 141, 85, 0.5)",
                    backgroundColor: processedData.map(x => `rgba(30, 141, 85, ${x.shipments * 100 / total}%)`),
                }],
            }} options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {display: false},
                    tooltip: {callbacks: {label: (tooltipItem) => `${tooltipItem.raw}%`}}
                },
                scales: {x: {ticks: {callback: (x) => `${x}%`}}},
                indexAxis: "y",
                onClick(event, elements, chart) {
                    const element = elements[0];
                    if (element && element.index >= 0 && processedData[element.index] &&
                        processedData[element.index]?.supplier_uri) setSupplierURI(processedData[element.index].supplier_uri)
                }
            }}/>
        </div>
    </div>

}

export default TopSupplier;