import {renderToStaticMarkup} from "react-dom/server";
import React from "react";

function Notification({id = Date.now(), message = "Hello World", tempo = "info"}) {
    return <div id={id}
                className={`notification ${(tempo === "info" ? "notification-info" : tempo === "success" ? "notification-success" : "notification-alert")}`}>
        <div className={"flex items-center gap-4 font-semibold"}>
            {
                tempo === "info" ? <span className={"material-symbols-rounded text-md"}>info</span>
                    :
                    tempo === "success" ? <span className={"material-symbols-rounded text-md"}>check_circle</span>
                        :
                        <span className={"material-symbols-rounded text-md"}>warning</span>
            }
            <span className={"text-sm"}>{message}</span>
        </div>
        <button className={"material-symbols-rounded text-md"}>close</button>
    </div>;

}

function Notify(message, tempo) {
    const id = Date.now();

    const notification = renderToStaticMarkup(Notification({id, message, tempo}));
    document.getElementById("notiStack").innerHTML += notification;

    const n = document.getElementById(id.toString());
    const btn = n.querySelector("button");
    btn.onclick = function () {
        n.remove();
    };

    setTimeout(() => {
        n.remove();
    }, 5000);
}

export default Notify;