import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {FormElement} from "../../components/Input";
import {activateUser, requestAuthCode} from "../../api/auth";


export default function Activate() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState(Array(5).fill(undefined));
    const [codeSent, setCodeSent] = useState(false);

    const requestCode = (evt) => {
        evt.preventDefault();
        if (email && evt.target.reportValidity()) {
            setLoading(true);
            requestAuthCode({email})
                .then(([status, _]) => {
                    switch (status) {
                        case 200: {
                            setCodeSent(true);
                            break;
                        }
                        case 404: {
                            document.querySelector('input[name=email]').focus();
                            setErrors({email: "User doesn't exist"});
                            break;
                        }
                        default: {
                            setErrors({form: "Something went wrong"});
                        }
                    }
                })
                .finally(() => setLoading(false));
        }
    }

    const activate = (evt) => {
        evt.preventDefault();
        if (evt.target.reportValidity() && codeSent && email &&
            (code.filter(c => typeof c === "number" && !isNaN(c)).length === 5)) {
            setLoading(true);
            activateUser({email, code: code.join("")})
                .then(([status, _]) => {
                    switch (status) {
                        case 200: {
                            navigate("/staging-app/activate-success");
                            break;
                        }
                        case 401: {
                            setErrors({email: "Invalid code"});
                            break;
                        }
                        case 404: {
                            setErrors({email: "User doesn't exist"});
                            break;
                        }
                        default: {
                            setErrors({form: "Something went wrong"});
                        }
                    }
                })
                .finally(() => setLoading(false));
        }
    }

    const handleKeys = (evt, index) => {
        if (evt.key === "Backspace") {
            const el = document.querySelector(`input[name=code-${Math.max(evt.target.value === "" ? index - 1 : index, 0)}]`);
            if (el) {
                let k = [...code];
                k[index] = undefined;
                setCode(k);
                el.focus();
            }
        }
    }

    const handleCode = (evt, index) => {
        const val = Number.parseInt(evt.target.value);
        if (!isNaN(val)) {
            let k = [...code];
            k[index] = val;
            setCode(k);
            if (index < 4)
                document.querySelector(`input[name=code-${index + 1}]`)?.focus();
        } else evt.target.value = "";
    }

    const clear = () => {
        setCodeSent(false);
        setCode(code.map(_ => null));
        setEmail("");
    }

    return <>
        <Helmet title={"Activate | Nimbey"}/>
        <form onSubmit={codeSent ? activate : requestCode}
              className={"flex flex-col card w-full shadow gap-4"}>
            <div className={"flex flex-col w-full"}>
                {codeSent && <span className={"text-4xl font-extrabold tracking-widest w-full text-left"}>*****</span>}
                {!codeSent && <span className={"text-lg font-bold"}>Activate Account</span>}
                {
                    codeSent ?
                        <p className={`text-gray-800 w-full text-sm`}>Please enter the one time password sent
                            to <br/>
                            <button onClick={clear} type={"button"}
                                    className={"flex items-center gap-2 font-semibold"}>{email}
                                <span className={"material-symbols-rounded text-sm"}>edit</span></button>
                        </p>
                        :
                        <p className={"text-gray-800 w-full text-xs"}>Please enter the email address associated with
                            your account, and we'll email you a link to activate your account</p>
                }
            </div>
            <div className={"flex flex-col w-full gap-3"}>
                {
                    codeSent && <div className={"flex items-center w-full gap-2  pb-2"}>
                        {
                            code.map((c, index) =>
                                <div key={`code-${index}`} className={"gap-3 w-10 overflow-hidden"}>
                                    <input name={`code-${index}`} autoComplete={"one-time-code"}
                                           type={"text"} onKeyDown={(evt) => handleKeys(evt, index)} value={c}
                                           onChange={evt => handleCode(evt, index)} disabled={loading}
                                           className={"p-2 text-center border-2 aspect-square flex items-center text-sm rounded-md outline-accLinks w-full"}
                                           maxLength={1}
                                    /></div>
                            )
                        }
                    </div>
                }
                {
                    !codeSent &&
                    <FormElement name={"email"} type={"email"} inputClass={"text-sm"} autoComplete={"email"}
                                 value={email} errors={errors}
                                 setValue={setEmail} required={true} autoFocus={true}/>
                }

                <button type={"submit"} disabled={loading}
                        className={"w-full px-4 py-3 bg-accentBack text-gray-200 rounded-xl flex items-center justify-center"}>
                        <span
                            className={`text-md animate-pulse material-symbols-rounded ${!loading && 'invisible'} absolute`}>more_horiz</span>
                    <span
                        className={`text-sm ${loading && 'invisible'}`}>{codeSent ? 'Activate' : 'Send Request'}</span>
                </button>
                {
                    codeSent && <div className={"w-full flex justify-center"}>
                        <button type={"button"} onClick={() => {
                            clear();
                            setEmail(email)
                        }}
                                className={"text-xs text-gray-600 hover:text-black font-medium"}>Resend Code
                        </button>
                    </div>
                }
            </div>
        </form>
    </>
}