import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import Img from "../../components/Img";
import {Link, useNavigate} from "react-router-dom";
import Input from "../../components/Input";
import {getCart, submitCart, updateCart} from "../../api/dashboard";
import notification from "../../components/Notification";
import cartSlice from "../../redux/cartSlice";

function Cart({cart, locations}) {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const [locationId, setLocationId] = useState("");
    const [deliverBy, setDeliverBy] = useState("");
    const [requestInfo, setRequestInfo] = useState("");
    const [loading, setLoading] = useState(false);

    // fetching cart contents
    useEffect(() => {
        setLoading(true);
        getCart()
            .then(([status, data]) => {
                if (status === 200) {
                    const prod = (data.products || [])[data.products?.length - 1];
                    if (prod) {
                        setLocationId(prod.location.location_id || "");
                        setRequestInfo(prod.request_info || "");
                        setDeliverBy(prod.expected_delivery_date?.split("T")[0] || "");
                    }
                    dispatch(cartSlice.actions.setCart(data));
                }
            })
            .catch(console.log)
            .finally(() => setLoading(false));
    }, [dispatch]);

    // changes quantity of the product and also deletes it
    const handleQuantity = (product, change) => {
        updateCart({
            location_id: product.location.location_id.toString(),
            request_info: (product.request_info || "").toString(),
            quantity: (change === 0 ? 0 : (product.quantity + change)).toString(),
            deliver_by: product.expected_delivery_date.toString(),
            product_id: product.product_id.toString(),
            action: "update"
        }).then(([status, _]) => {
            if (status === 200) {
                dispatch(cartSlice.actions.updateProduct({
                    ...product,
                    quantity: (change === 0 ? 0 : (product.quantity + change))
                }));
            }
        });
    }

    const navigate = useNavigate();

    // buys the cart
    const handleSubmit = (e) => {
        e.preventDefault()
        if (e.target.reportValidity()) {
            setLoading(true);
            submitCart({
                deliver_by: deliverBy.toString(),
                location_id: locationId.toString(),
                request_info: requestInfo.toString(),
                products: cart.products.reduce((prev, curr) => ({...prev, [curr.product_id]: curr.quantity}), {})
            })
                .then(([status, _]) => {
                    if (status === 200) {
                        dispatch(cartSlice.actions.setCart({id: null, products: []}));
                        const cartValue = cart.products.reduce((prev, curr) => prev + (curr.quantity * curr.best_price), 0).toFixed(2);
                        navigate("/staging-app/dashboard/success", {
                            state: {
                                message: "Order placed successfully!",
                                details: `Cart Value: $${cartValue}`,
                                ref: window.location.href
                            }
                        });
                    } else {
                        notification("Order Failed", "err");
                    }
                })
                .finally(() => setLoading(true))
        }
    }

    const totalAmount = cart.products.reduce((prev, curr) => prev + (curr.quantity * curr.unit_price), 0);
    const totalAfterDiscount = cart.products.reduce((prev, curr) => prev + (curr.quantity * curr.best_price), 0);
    const discount = totalAmount - totalAfterDiscount;


    return <div className={"flex flex-col w-full flex-1 px-2 bg-gray-100"}>
        <div className={"flex items-center justify-between gap-2 w-full"}>
            <span
                className={"flex text-xl gap-1 w-full p-2"}><b>Checkout | </b> Cart</span>
        </div>
        {
            loading ?
                <div className={"flex w-full h-full items-center justify-center"}>
                    <span className={"material-symbols-rounded text-lg animate-spin"}>progress_activity</span>
                </div>
                :
                <div className={"flex w-full mt-4 items-start gap-4"}>
                    <ul className={"flex flex-col gap-2 bg-white card flex-[3]"}>
                        {
                            cart.products.map(product =>
                                <li key={`product-${product.product_id}`}
                                    className={"flex border-b items-stretch w-full justify-between gap-2 p-4"}>
                                    <div className={"flex items-center gap-4"}>
                                        <Img className={"w-20"} src={product.product_image}/>
                                        <div className={"flex flex-col gap-1"}>
                                            <Link to={`/staging-app/dashboard/products/${product.product_id}`}
                                                  className={"text-md font-bold mb-1"}>{product.product_name}</Link>
                                            <span
                                                className={"text-xs text-green-500 font-semibold"}>Supplier: {product.supplier_name}</span>
                                            <span className={"text-xs text-green-500 font-semibold"}>Unit Price: <b
                                                className={"font-bold"}>${product.best_price}</b></span>
                                        </div>
                                    </div>
                                    <div className={"flex flex-col items-end justify-between gap-2"}>
                                        <div className={"flex flex-col items-end"}>
                                        <span
                                            className={"font-bold text-md"}>${(product.best_price * product.quantity).toLocaleString("en-US", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })}</span>
                                            <span className={"text-gray-600 text-xs"}>${product.best_price}
                                                <span>x</span> {product.quantity}</span>
                                        </div>
                                        <div className={"flex items-center gap-4"}>
                                            <button onClick={() => handleQuantity(product, 0)}
                                                    className={"material-symbols-rounded p-2 hover:bg-gray-100 rounded-full text-md text-red-500"}>delete
                                            </button>
                                            <div
                                                className={"flex items-center border my-2 rounded-md w-fit overflow-hidden"}>
                                                <button onClick={() => handleQuantity(product, -1)}
                                                        className={"material-symbols-rounded hover:bg-gray-50 p-1.5 w-fit text-md"}>remove
                                                </button>
                                                <span className={"text-sm font-medium px-3"}>{product.quantity}</span>
                                                <button onClick={() => handleQuantity(product, 1)}
                                                        className={"material-symbols-rounded hover:bg-gray-50 p-1.5 w-fit text-md"}>add
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </li>)
                        }
                        {
                            !!cart.products.length &&
                            <li key={"sub-total"} className={"flex w-full justify-end p-4"}>
                        <span
                            className={"font-semibold flex text-md items-center gap-2"}>SubTotal ({cart.products.length} item{cart.products.length > 1 ? 's' : ''}):
                            <b className={"font-bold"}>${cart.products.reduce((prev, curr) => prev + (curr.quantity * curr.best_price), 0).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</b></span>
                            </li>
                        }
                    </ul>
                    <form onSubmit={handleSubmit} className={"flex flex-col flex-1 gap-4"}>
                        <div className={"flex flex-col gap-4 bg-white card"}>
                            <span className={"text-sm text-cyan-500 uppercase font-bold"}>Order Information</span>

                            <div className={"flex flex-col w-full gap-1"}>
                                <span className={"text-xs text-gray-600"}>When do you require this by?</span>
                                <Input id={"deliverBy"} name={"deliverBy"} value={deliverBy}
                                       className={"border-gray-200"}
                                       min={new Date().getFullYear() + "-" + new Date().toLocaleDateString().split("/").slice(0, 2).map(x => x.toString().padStart(2, '0')).join("-")}
                                       required={true} errors={errors.deliverBy} inpStyle={"text-xs"}
                                       type={"date"} onChange={(e) => setDeliverBy(e.target.value)}/>
                            </div>

                            <div className={"flex flex-col w-full gap-1"}>
                                <span className={"text-xs text-gray-600"}>Choose Location</span>
                                <Input id={"location"} name={"location"} value={locationId}
                                       className={"border-gray-200"}
                                       options={locations.map(l => ({id: l.id, name: `${l.city}, ${l.state}`}))}
                                       required={true} errors={errors.location} inpStyle={"text-xs"}
                                       endProp={<span
                                           className={"material-symbols-rounded text-lg "}>expand_more</span>}
                                       type={"select"} onChange={(e) => setLocationId(e.target.value)}/>
                            </div>

                            <div className={"flex flex-col w-full gap-1"}>
                                <span className={"text-xs text-gray-600"}>Details about request</span>
                                <Input id={"requestInfo"} name={"requestInfo"} value={requestInfo}
                                       placeholder={"Enter details"} className={"border-gray-200"}
                                       errors={errors.requestInfo} inpStyle={"text-xs w-full"} maxLength={240}
                                       required={false}
                                       type={"text"} onChange={(e) => setRequestInfo(e.target.value)}/>
                            </div>
                        </div>
                        <div className={"flex flex-col gap-4 bg-white card"}>
                            <div className={"flex flex-col gap-1 w-full"}>
                                <span className={"text-sm mb-2 text-cyan-500 uppercase font-bold"}>Order Summary</span>
                                {/*  total amount  */}
                                <div className={"flex items-center text-gray-600 justify-between w-full"}>
                                    <span className={"text-sm"}>
                                        Total Amount:
                                    </span>
                                    <span className={"text-sm font-bold"}>
                                        ${cart.products.reduce((prev, curr) => prev + (curr.quantity * curr.unit_price), 0).toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                    </span>
                                </div>

                                {/*  tax  */}
                                <div className={"flex items-center text-gray-600 justify-between w-full"}>
                                    <span className={"text-sm"}>
                                        Tax:
                                    </span>
                                    <span className={"text-sm font-bold"}>
                                        $0.00
                                    </span>
                                </div>

                                {/*  discount  */}
                                <div className={"flex items-center text-gray-600 justify-between w-full"}>
                                    <span className={"text-sm"}>
                                        Discount:
                                    </span>
                                    <span className={"text-sm font-bold"}>
                                        - ${discount.toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                    </span>
                                </div>

                                {/*  subtotal amount  */}
                                <div className={"flex mt-4 items-center justify-between w-full"}>
                                    <span className={"font-semibold text-md"}>
                                        SubTotal ({cart.products.length} item{cart.products.length > 1 ? 's' : ''}):
                                    </span>
                                    <span className={"text-md text-green-600 font-bold"}>
                                        ${cart.products.reduce((prev, curr) => prev + (curr.quantity * curr.best_price), 0).toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                    </span>
                                </div>

                                <span className={"gap-1 items-center whitespace-nowrap text-yellow-500 text-xs hidden"}>
                                <span
                                    className={"material-symbols-rounded text-md font-semibold"}>local_shipping</span> Delivery by {
                                    new Date(Date.now() + 700000000).toLocaleDateString("default", {
                                        month: "long",
                                        day: "2-digit",
                                        year: "numeric"
                                    })
                                }</span>
                            </div>
                            <button type={"submit"} id={"submitOrder"}
                                    className={"p-3 rounded-md px-12 bg-nimbey hover:bg-lime-400 w-full font-bold text-sm"}>
                                {loading ? <span
                                    className={"material-symbols-rounded text-sm animate-spin"}>progress_activity</span> : "Place Order"}
                            </button>
                        </div>
                    </form>
                </div>
        }
    </div>
}

export default connect(state => ({locations: state.userData.locations, cart: state.cart,}))(Cart);