import {Doughnut} from "react-chartjs-2";
import React from "react";
import {colors, reduceStats} from "./utils";

function OrderRequestType({data, filters, setFilters, isCurrent, postFilters}) {
    const [reduced, index] = reduceStats(data, "new_request");

    const labels = ["Requested", "From Catalog"]
    const datasets = [];

    index.slice(isCurrent ? 0 : 1, isCurrent ? 1 : 2).forEach((index, _in) =>  {
        datasets.push({
            label: `In ${index}`,
            backgroundColor: colors,
            data: labels.map(x => (reduced[x === "Contracted"] || {})[index] || 0),
        })
    })

    return <Doughnut id={"order-request"} width={"100%"} className={"p-2"}
                     data={{
                         labels: labels,
                         datasets: datasets,
                     }}
                     options={{
                         responsive: true,
                         maintainAspectRatio: false,
                         aspectRatio: 1,
                         onClick(event, elements, chart) {
                             if (elements.length && elements[0].element) {
                                 if (filters?.new_request === (elements[0].index === 0)) {
                                     let d = {...filters};
                                     delete d.new_request;
                                     setFilters(d);
                                 } else {
                                     setFilters({...filters, new_request: elements[0].index === 0})
                                 }
                             }
                         },
                         plugins: {
                             tooltip: {
                                 callbacks: {
                                     label(tooltipItem) {
                                         return `${tooltipItem.dataset.label} : $${tooltipItem.formattedValue}`
                                     }
                                 }
                             }
                         }
                     }}
    />
}

export default OrderRequestType;