import React, {useState} from "react";
import {useNavigate, Link} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {FormElement} from "../../components/Input";
import {signup} from "../../api/auth";


export default function SignUp() {

    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [role, setRole] = useState("");
    const [department, setDepartment] = useState("");
    const [company, setCompany] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);


    const [errors, setErrors] = useState({});

    const handleSubmit = (e) => {
        e.preventDefault();
        if (name && email && password && e.target.reportValidity()) {
            setLoading(true);
            signup({name, company, department, role, email, password})
                .then(([status, _]) => {
                    switch (status) {
                        case 200: {
                            navigate(`/staging-app/activate`, {state: {email}});
                            break;
                        }
                        case 409: {
                            setErrors({email: "Email already taken"});
                            document.querySelector("input[name=email]")?.focus();
                            break;
                        }
                        default: {
                            setErrors({form: "Something went wrong"});
                        }
                    }
                })
                .finally(() => setLoading(false));
        }
    }


    return <>
        <Helmet title={"Signup | Nimbey"}/>

        <form onSubmit={handleSubmit} className={"flex flex-col card w-full shadow"}>

            <span className={"text-lg font-bold"}>SignUp</span>
            <span className={"text-xs"}>Already have an account? <Link to={"/staging-app/login"}
                                                                       className={"text-accLinks font-semibold"}>Login</Link></span>

            <div className={"flex flex-col w-full py-4 gap-3"}>
                <FormElement name={"name"} type={"text"} autoComplete={"name"} value={name} errors={errors}
                             setValue={setName} required={true} autoFocus={true} maxLength={40}/>
                <FormElement name={"email"} type={"email"} autoComplete={"email"} value={email} errors={errors}
                             setValue={setEmail} required={true} autoFocus={true} maxLength={255}/>
                <FormElement name={"password"} type={"password"} autoComplete={"new-password"}
                             minLength={6} maxLength={40} required={true} errors={errors}
                             value={password} setValue={setPassword} toggleView={true}/>
                <div className={"flex items-center gap-4"}>
                    <FormElement name={"role"} type={"text"} autoComplete={"role"} value={role} errors={errors}
                                 setValue={setRole} required={true} autoFocus={true} maxLength={50}/>
                    <FormElement name={"department"} type={"text"} autoComplete={"department"} value={department}
                                 errors={errors} maxLength={40}
                                 setValue={setDepartment} required={true} autoFocus={true}/>
                </div>
                <FormElement name={"company"} type={"text"} autoComplete={"company"} value={company} errors={errors}
                             setValue={setCompany} required={true} autoFocus={true} maxLength={40}/>

                {errors.form && <span className={"text-red-400 font-semibold text-xs"}>{errors.form}</span>}

                <button type={"submit"} disabled={loading}
                        className={"w-full px-4 py-3 bg-accentBack text-gray-200 rounded-xl flex items-center justify-center"}>
                        <span
                            className={`text-md animate-pulse material-symbols-rounded ${!loading && 'invisible'} absolute`}>more_horiz</span>
                    <span className={`text-sm ${loading && 'invisible'}`}>Create Account</span>
                </button>
                <span className={"text-xs w-full text-center"}>By signing up, I agree to <Link
                    className={"text-black underline"} to={"#"}>Terms of Use</Link>
                    <br/>and <Link className={"text-black underline"} to={"#"}>Privacy Policy</Link>.
                </span>

            </div>
        </form>
    </>

}