import request, {auth} from "./init";


function login(payload) {
    return request(`${auth}/login`, payload);
}

function signup(payload) {
    return request(`${auth}/signup`, payload);
}

function requestAuthCode(payload) {
    return request(`${auth}/code`, payload);
}

function verifyAuthCode(payload) {
    return request(`${auth}/verify-code`, payload);
}

function activateUser(payload) {
    return request(`${auth}/activate`, payload);
}

function secure() {
    return request(`${auth}/secure`, null, "GET");
}

function logout() {
    return request(`${auth}/logout`, null, "GET");
}

function resetPassword(payload) {
    return request(`${auth}/reset-password`, payload);
}

export {login, signup, requestAuthCode, verifyAuthCode, activateUser, secure, logout, resetPassword};
