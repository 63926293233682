import {getTimePeriod, handleTip} from "./utils";
import React, {useState} from "react";
import ContractedProducts from "./ContractedProducts";
import OrderRequestType from "./OrderRequestType";

function Dials({data, term, filters, setFilters, postFilters}) {
    const activeTab = term === 0 ? "Year" : term === 1 ? "Quarter" : "Month";
    const [isCurrent, setIsCurrent] = useState(true);

    return <div id={"contract-prod-graph"}  className={"flex flex-col card aspect-[0.8] lg:aspect-square flex-1 lg:max-h-96"}>
        <div className={"flex flex-col item-start justify-between w-full whitespace-nowrap"}>

                <div className={"flex items-center w-full justify-between gap-2 relative"}>
                    <span className={"font-bold text-gray-800 text-md"}>Product Type</span>

                    <div className={"flex items-center gap-4"}>
                        <div className={"flex items-center text-md border rounded-md overflow-hidden"}>
                            <button onClick={() => setIsCurrent(!isCurrent)}
                                className={"material-symbols-rounded hover:bg-gray-100 p-1 text-md disabled:opacity-50"}
                                disabled={!isCurrent}>chevron_left
                            </button>
                            <button onClick={() => setIsCurrent(!isCurrent)}
                                className={"material-symbols-rounded hover:bg-gray-100 p-1 text-md disabled:opacity-50"}
                                disabled={isCurrent}>chevron_right
                            </button>
                        </div>

                        <div onMouseEnter={(e) => handleTip(e, "contract-prod-graph")}
                             onMouseLeave={(e) => handleTip(e, "contract-prod-graph")}
                             className={"show-tip"}>
                            <span className={"material-symbols-rounded text-md"}>info</span>
                            <div className={"tool-tip-cover"}>
                    <span
                        className={"p-2 block px-4 whitespace-normal text-sm w-[20rem]"}>{`The chart shows spending across departments in ${activeTab} (${getTimePeriod(term)})`}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <span className={"text-xs text-gray-600"}>{isCurrent ? 'Latest' : 'Previous'} {activeTab}</span>
            </div>
        <div className={"w-full flex-1 flex flex-col lg:flex-row lg:items-center"}>
            <div className={"flex-1 h-full overflow-hidden"}>
                <ContractedProducts isCurrent={isCurrent} postFilters={postFilters} data={data} setFilters={setFilters} filters={filters}/>
            </div>
            <div className={"flex-1 h-full overflow-hidden"}>
                <OrderRequestType isCurrent={isCurrent} postFilters={postFilters} data={data} setFilters={setFilters} filters={filters}/>
            </div>
        </div>
    </div>
}

export default Dials;
