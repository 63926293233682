import React, {useEffect, useRef, useState} from "react";
import Input from "./Input";
import Notify from "./Notification";
import {contractUpload} from "../api/dashboard";

function ContractUploadModal({getContracts}) {
    const [status, setStatus] = useState(null);

    const [contractType, setContractType] = useState(null);
    const [files, setFiles] = useState([]);

    const filesAccepted = ["pdf"];

    const inputRef = useRef();
    const modalRef = useRef();

    const handleDialog = () => {
        if (modalRef.current.open) {
            inputRef.current.value = "";
            setFiles([]);
            modalRef.current.close();
        } else {
            if (status && status.loaded === status.total) setStatus(null);

            if (files.filter(f => !f.discard).length) modalRef.current.showModal(); else {
                inputRef.current.click();
            }
        }
    }

    const handleChange = (evt) => {
        if (status) return;
        let files = [];

        if (evt.type === "drop") {
            files.push(...[...evt.dataTransfer.files].slice(0, 1))
        } else if (evt.target.files.length) {
            files.push(...[...evt.target.files].slice(0, 1))
        }

        if (files.length) {
            console.log("Files accepted: ", files.map(f => f.name).join(", "));
            setFiles(files);
            modalRef.current.showModal();
        } else modalRef.current.close();
    }

    const uploadFile = () => {
        if (!files.length) return;

        const formData = new FormData();
        formData.append("contract_type", contractType);
        formData.append('files', files[0], files[0].name);

        modalRef.current.close();

        contractUpload(formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress: (ev) => setStatus(ev)
        }).then(([status, data]) => {
            if (status === 200) {
                Notify("Contract uploaded successfully", "success");
                inputRef.current.value = "";
                setFiles([]);
                setContractType(null);
                getContracts();
            }
        }).finally(() => setStatus(null));
    }

    useEffect(() => {
        if (files.filter(f => !f.discard).length === 0) modalRef.current.close();
    }, [files]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if ((contractType != null && files.length) || e.target.reportValidity()) {
            uploadFile();
        }
    }

    const isFilesValid = files => files.length && files.every((f) => filesAccepted
        .includes(f.name.slice(f.name.lastIndexOf('.') + 1)) && f.size <= 5 * 1024 * 1024);

    const handleOnDrop = (evt) => {
        evt.preventDefault();
        handleChange(evt);
    }

    return (<>
        <div id={"dropzone"}
             className={"flex p-4 py-6 flex-col items-center justify-center w-full bg-gray-100 hover:bg-gray-200 transition-all ease-in border border-dotted border-gray-400 rounded-lg cursor-pointer overflow-hidden"}
             onClick={handleDialog} onDrop={handleOnDrop} onDragOver={(e) => e.preventDefault()} draggable={true}>
            {
                status ? <>
                    <span className={"material-symbols-rounded animate-spin"}>progress_activity</span>
                    <span className={"text-center text-sm text-gray-600 font-bold"}>
                    {status.loaded === status.total ? "Processing..." : `Uploading... ${status.loaded / status.total * 100}%`}
                </span>
                </> : <>
                    <span className={"material-symbols-rounded mso-fill text-3xl text-gray-600"}>cloud_upload</span>
                    <span className={"text-center text-sm text-gray-600"}>
                                        Click to browse or drop here to upload.<br/>
                                        Supported Formats: {filesAccepted.map(f => `.${f}`).join(", ")}. Maximum
                                        Individual File size: 5 MB
                    </span>
                </>
            }
        </div>
        <dialog ref={modalRef}
                className={"backdrop:backdrop-blur-[1px] hidden open:flex z-10 bg-white max-w-[480px] w-full p-8 outline-0 shadow rounded-lg flex-col items-center"}>
            <span className={"font-bold mb-4 text-lg"}>Upload File</span>
            <span className={"text-sm w-full"}>Click here to select the document type, and then click on the area below to upload the document.</span>

            <form onSubmit={handleSubmit} method={"POST"} className={"w-full mt-4"}>
                <Input id={"document type"} name={"document type"} onChange={(e) => setContractType(e.target.value)}
                       required={true}
                       value={contractType} type={"select"} inpStyle={"w-full"}
                       options={[{name: "General Contract", id: 0}, {name: "Pricing List", id: 1}]}
                       endProp={<span className={"material-symbols-rounded text-xl"}>expand_more</span>}/>

                <input ref={inputRef} required={!files.length}
                       onChange={handleChange}
                       type={"file"} multiple={false} hidden={true} name={'contract'}
                       accept={filesAccepted.map(f => `.${f}`).join(", ")}
                />
                <ul className={"w-full max-h-40 mt-4 overflow-y-auto light-scroll"}>
                    {
                        files.map((file, index) =>
                            <li key={`${file.name}-${index}`}
                                className={"text-nowrap w-full px-4 py-3 bg-gray-100 rounded-md flex items-center justify-between gap-4"}>
                                <div className={"overflow-hidden flex flex-col"}>
                                    <span
                                        className={"text-sm font-medium overflow-hidden overflow-ellipsis"}>{file.name}</span>
                                    {
                                        !isFilesValid([file]) &&
                                        <span
                                            className={"text-xs font-medium overflow-hidden overflow-ellipsis text-red-600 mt-1"}>
                                            {file.size > 5 * 1024 * 1024 ? "File size is greater than 5MB is not allowed." : filesAccepted
                                                .includes(file.name.slice(file.name.lastIndexOf('.') + 1)) ? "Selected file format is not accepted." : ""}
                                        </span>
                                    }
                                </div>
                                <div className={"flex items-center gap-4"}>
                                    <button className={"material-symbols-rounded text-xl"}
                                            onClick={() => setFiles(files.toSpliced(index, 1))}>close
                                    </button>
                                </div>
                            </li>)
                    }
                </ul>

                <div className={"flex w-full flex-col items-center gap-1 mt-10"}>
                    <button type={"submit"}
                            disabled={!contractType || !isFilesValid(files)}
                            className={"w-full font-medium bg-accentBack disabled:bg-gray-200 p-3 text-white disabled:text-black rounded-md text-sm"}>Upload
                    </button>
                    <button type={"button"}
                            onClick={() => modalRef.current.close()}
                            className={"font-medium hover:bg-gray-100 w-full p-2 px-3 text-black rounded-md text-sm"}>Back
                    </button>
                </div>

            </form>
        </dialog>
    </>)
}

export default ContractUploadModal;