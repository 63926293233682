import {Link, useOutletContext} from "react-router-dom";
import React from "react";
import DropDown from "./DropDown";


function AnalysisRecord({statusUpdates, record, setDescribeAnalysis, handleDelete}) {
    const {chat} = useOutletContext();
    const id = record.invoice_number ? `Invoice: ${record.invoice_number}` : `Record: ${record.id}`
    const status = statusUpdates[record.id]?.status || record.status;

    const handleDetail = () => {
        if (status === "Active")
            setDescribeAnalysis(record.id);
    }

    const handleExpand = (ev) => ev.target?.parentElement?.nextSibling?.classList?.toggle(chat ? "expand-contract-xl" : "expand-contract-lg");



    return <div key={id}
                className={`flex flex-col ${chat ? "xl:flex-row" : "lg:flex-row"} items-center w-full card p-0 border`}>
        <div className={`flex w-full items-center flex-[4] p-4`}>
            <div className={"flex flex-[1.5] gap-4 items-center overflow-hidden"}>
                <span className={"material-symbols-rounded text-xl"}>receipt_long</span>
                <div className={"flex flex-col w-full overflow-hidden"}>
                    <span onClick={handleDetail}
                          className={`font-semibold text-nowrap overflow-hidden text-ellipsis text-sm ${(status === "Active") ? 'cursor-pointer' : ''}`}>
                            {id}</span>
                </div>
            </div>
            <div className={`hidden ${chat ? "xl:flex" : "lg:flex"} flex-1 items-center justify-start`}><span
                className={"block flex-1 text-xs text-gray-600"}>{new Date(record.created_at).toDateString()}</span>
            </div>

            <div
                className={`hidden ${chat ? "xl:flex" : "lg:flex"} flex-1 items-center justify-start`}><span
                className={"block flex-1 text-xs font-semibold"}>{record.recoverable_amount || "-"}</span></div>
            <div
                className={`hidden ${chat ? "xl:flex" : "lg:flex"} flex-1 items-center justify-start`}><span
                className={"block flex-1 text-xs text-gray-600 capitalize"}>{record.priority || "-"}</span></div>
            <div
                className={`hidden ${chat ? "xl:flex" : "lg:flex"} flex-1 items-center justify-start`}><span
                className={"block flex-1 text-xs text-gray-600 capitalize"}>{record.ra_status || "-"}</span></div>


            <button onClick={handleExpand} className={`material-symbols-rounded ${chat ? "xl:hidden" : "lg:hidden"}`}>
                expand_more
            </button>
        </div>
        <div
            className={`w-full flex ${chat ? "xl:flex-[0.5]" : "lg:flex-[0.5]"} hidden ${chat ? "xl:flex" : "lg:flex"} bg-gray-50 ${chat ? "xl:bg-white" : "lg:bg-white"} border-t p-4 ${chat ? "xl:border-none" : "lg:border-none"} items-center justify-between`}>
            {
                status === "Active" ?
                    <button
                        className={`flex lg:hidden border hover:bg-gray-100 hover:border-gray-100 rounded-md w-fit text-xs px-3 py-2 capitalize`}>
                        View More
                    </button>
                    :
                    <></>
            }
            <div className={"flex flex-1 items-center justify-end gap-4"}>
                {
                    status === "Active" ?
                        <span className={"material-symbols-rounded text-lg text-green-400"}
                              title={"Active"}>check</span>
                        :
                        status === "Not processing" ?
                            <span className={"material-symbols-rounded text-lg text-yellow-500"}
                                  title={"Not processing"}>warning</span>
                            :
                            <span className={"material-symbols-rounded text-sm animate-spin"}
                                  title={"Processing"}>progress_activity</span>
                }

                <button onClick={() => handleDelete(record.id)}
                        className={"material-symbols-rounded text-lg disabled:text-gray-400"}>delete
                </button>

                <DropDown
                    options={Object.fromEntries(record.docs.map((doc, index) => [index, doc]))}
                    Item={({docKey, name}) => {
                        return <Link key={name}
                                     to={`${process.env.REACT_APP_API_URL}/api/admin/invoice-analysis/download?invoice_id=${record.id}&doc_key=${docKey}`}
                                     rel={'noreferrer'} target={"_blank"}
                                     className={" text-xs cursor-pointer bg-white px-4 py-2 border-b hover:bg-gray-100"}>
                            {name.split("-").slice(2,).join("-")}
                        </Link>
                    }}>
                    <span
                          className={"material-symbols-rounded text-lg cursor-pointer"}>download</span>
                </DropDown>
            </div>
        </div>
    </div>
}

export default AnalysisRecord;