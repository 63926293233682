import Comparison from "../components/Comparison";
import {Line} from "react-chartjs-2";

function Statistics({tab, filings, setTab, secLoading, data}) {
    const vendorTimes = {};
    for (let i of data.shipments) {
        const year = (new Date(i.timeline.toString())).getFullYear();
        vendorTimes[year] = [...(vendorTimes[year] || []), {...i, timeline: new Date(i.timeline)}];
    }


    const seaShips = {};
    for (let i of data.sea_shipments) {
        const year = (new Date(i.date.toString())).getFullYear();
        seaShips[year] = [...(seaShips[year] || []), {...i, timeline: new Date(i.date)}];
    }

    const k = (ss) => {

        const year_now = new Date().getFullYear();
        const [prevYear, latYear] = Object.keys(ss).sort().slice(-2);
        const preYearDate = new Date(new Date().setFullYear(Number(prevYear)));
        console.log(preYearDate);
        const lat_ss = ss[latYear];
        const pre_ss = latYear === year_now.toString() ? ss[prevYear]
            .filter(x => new Date(x.timeline.toString()) <= preYearDate) : ss[prevYear];

        return [pre_ss, lat_ss];
    }

    const [prev_shipments, lat_shipments] = k(vendorTimes);
    const [prev_sea_shipments, lat_sea_shipments] = k(seaShips);

    const curr_total_suppliers = new Set(lat_shipments
        ?.map(x => x.supplier_uri) || []);

    const prev_total_suppliers = new Set(prev_shipments
        ?.map(x => x.supplier_uri)) || 0;

    const curr_supp_purchases = lat_sea_shipments
        ?.reduce((prev, curr) => prev + curr.shipments, 0) || 0;

    const prev_supp_purchases = prev_sea_shipments
        ?.reduce((prev, curr) => prev + (curr.shipments || 0), 0) || 0;

    const curr_cats = new Set(lat_shipments
        ?.reduce((prev, curr) => [...prev, ...curr.hs_codes], []) || []);

    const prev_cats = new Set(prev_shipments
        ?.reduce((prev, curr) => [...prev, ...curr.hs_codes], []) || []);


    return <div className={`flex flex-col flex-1 bg-white card p-0 ${!!tab && 'md:hidden'}`}>
        <div className={"flex items-center w-full justify-between p-4 pb-1 md:pb-0"}>
            <div className={"flex items-center gap-2"}>
                <span className={"material-symbols-rounded text-md"}>drag_indicator</span>
                <span
                    className={"text-sm font-semibold"}>Quick Stats</span>
            </div>
            {
                secLoading && <span className={"text-xss items-center gap-1 hidden md:flex"}>Loading <span
                    className={"animate-spin material-symbols-rounded text-xs"}>progress_activity</span></span>
            }
        </div>
        <div className={"flex flex-col items-center justify-center flex-1 md:flex-row w-full gap-2 p-2"}>
            {!!filings?.length && <button onClick={() => setTab(false)}
                                          className={`material-symbols-rounded hidden md:block ${!tab ? 'text-gray-400': 'text-black'}`}>keyboard_arrow_left</button>}
            <div className={"flex flex-col items-start p-3 w-full md:flex-1 rounded-md bg-gray-50"}>
                <div className={"flex flex-1 items-center justify-between gap-2 w-full"}>
                    <span className={"text-sm text-gray-600 font-semibold"}>Supplier Purchases</span>
                    <span className={"text-md material-symbols-rounded"}>more_vert</span>
                </div>
                <div className={"flex flex-1 items-center gap-2 w-full"}>
                    <div className={"flex w-[50%] flex-col"}>
                    <span
                        className={"text-xl font-bold"}>{(curr_supp_purchases || "NA").toString().padStart(2, '0')}</span>
                        <Comparison curr={curr_supp_purchases} prev={prev_supp_purchases} text={"last year"}/>
                    </div>
                    <Line className={"max-h-12 max-w-20"}
                          data={{
                              labels: Object.keys(vendorTimes).slice(-6), datasets: [{
                                  data: Object.values(vendorTimes).slice(-6).map(x => x.reduce((prev, curr) => prev + curr.shipments, 0)),
                                  borderColor: 'rgb(75, 192, 192)',
                                  tension: 0.1
                              }]
                          }} options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {legend: {display: false},},
                        scales: {
                            x: {border: {display: false}, ticks: {display: false}, grid: {display: false}},
                            y: {border: {display: false}, ticks: {display: false}, grid: {display: false}}
                        },
                    }}/>
                </div>
            </div>

            <div className={"flex flex-col items-start p-3 w-full md:flex-1 rounded-md bg-gray-50"}>
                <div className={"flex flex-1 items-center justify-between gap-2 w-full"}>
                    <span className={"text-sm text-gray-600 font-semibold"}>Total Suppliers</span>
                    <span className={"text-md material-symbols-rounded"}>more_vert</span>
                </div>
                <div className={"flex flex-1 items-center gap-2 w-full"}>
                    <div className={"flex w-[50%] flex-col"}>
                    <span className={"text-xl font-bold"}>
                                    {(curr_total_suppliers.size || "NA").toString().padStart(2, '0')}
                                </span>
                        <Comparison curr={curr_total_suppliers.size} prev={prev_total_suppliers.size}
                                    text={"last year"}/>
                    </div>
                    <Line className={"max-h-12 max-w-20"}
                          data={{
                              labels: Object.keys(vendorTimes).slice(-6), datasets: [{
                                  data: Object.values(vendorTimes).slice(-6).map(x => x.length),
                                  borderColor: 'rgb(75, 192, 192)',
                                  tension: 0.1
                              }]
                          }} options={{
                        responsive: true, maintainAspectRatio: false, plugins: {legend: {display: false},},
                        scales: {
                            x: {border: {display: false}, ticks: {display: false}, grid: {display: false}},
                            y: {border: {display: false}, ticks: {display: false}, grid: {display: false}}
                        },
                    }}/>

                </div>
            </div>

            <div className={"flex flex-col items-start p-3 w-full md:flex-1 rounded-md bg-gray-50"}>
                <div className={"flex flex-1 items-center justify-between gap-2 w-full"}>
                    <span className={"text-sm text-gray-600 font-semibold"}>Total Categories</span>
                    <span className={"text-md material-symbols-rounded"}>more_vert</span>
                </div>
                <div className={"flex flex-1 items-center gap-2 w-full"}>
                    <div className={"flex w-[50%] flex-col"}>
                        <span className={"text-xl font-bold"}>{curr_cats.size.toString().padStart(2, '0')}</span>
                        <Comparison curr={curr_cats.size} prev={prev_cats.size} text={"last year"}/>
                    </div>
                    <Line className={"max-h-12 max-w-20"}
                          data={{
                              labels: Object.keys(vendorTimes).slice(-6), datasets: [{
                                  data: Object.values(vendorTimes).slice(-6).map(k => new Set(k.reduce((prev, curr) => [...prev, ...curr.hs_codes], [])).size),
                                  borderColor: 'rgb(75, 192, 192)',
                                  tension: 0.1
                              }]
                          }} options={{
                        responsive: true, maintainAspectRatio: false, plugins: {legend: {display: false},},
                        scales: {
                            x: {border: {display: false}, ticks: {display: false}, grid: {display: false}},
                            y: {border: {display: false}, ticks: {display: false}, grid: {display: false}}
                        },
                    }}/>
                </div>
            </div>
            {!!filings?.length && <button onClick={() => setTab(true)}
                                          className={"material-symbols-rounded hidden md:block"}>keyboard_arrow_right</button>}
        </div>
    </div>
}

export default Statistics;