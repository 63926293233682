import React, {useState} from "react";

export default function Sorter({sort, setSort, columns}) {

    const [sortOpen, setSortOpen] = useState(false);

    return <div className={"flex flex-col gap-4 relative"}>
        <button onClick={() => setSortOpen(!sortOpen)}
                className={"px-3 py-1 flex items-center card p-0 whitespace-nowrap text-xs gap-1 hover:bg-gray-200 rounded-md border-transparent"}>
            <span className={"material-symbols-rounded text-lg"}>sort</span>
            Sort by
        </button>
        {
            sortOpen &&
            <ul className={"bg-white shadow-xl rounded-lg overflow-hidden absolute top-10 right-0 border z-20"}>
                {Object.values(columns).map(c =>
                    c.id && <li key={c.id}
                        className={"select-none px-3 py-2 hover:bg-gray-100 text-xs whitespace-nowrap cursor-pointer" +
                            " flex items-center gap-2 justify-between"}
                        onClick={() => setSort({
                            id: c.id,
                            desc: sort ? sort.id === c.id && !sort.desc : false
                        })}>
                        <span className={"whitespace-nowrap"}>{c.name}</span>
                        <span className={`${!(sort && sort.id === c.id) && 'invisible'} select-none
                         material-symbols-rounded text-md`}>
                                            {sort && !sort.desc ? "arrow_upward" : "arrow_downward"}</span>

                    </li>
                )}
            </ul>
        }
    </div>
}