import {useEffect, useState} from "react";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import TopBar from "../views/side-quests/components/TopBar";
import Sidebar from "../views/side-quests/components/Sidebar";
import Chat from "../components/Chat";
import {sideQuestAccess, sideQuestChat} from "../api/sideQuest";

function SideQuestLayout() {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [chat, setChat] = useState(true);
    const {uri} = useParams();
    console.log("uri", uri);
    // access check
    useEffect(() => {
        sideQuestAccess().then(([status, data]) => {
            if (status === 200) {
                setUser(data);
            } else navigate("/staging-app/login", {state: {ref: window.location.href}});
        })
    }, []);

    if (!user) return <div className={"flex w-full h-full items-center justify-center"}>
        <span className={"material-symbols-rounded animate-spin"}>progress_activity</span>
    </div>

    return <div className={"flex w-full h-full bg-gray-50 gap-2 overflow-hidden overflow-y-auto md:p-2"}>
        {/*  sidebar   */}
        <Sidebar />

        {/*  content  */}
        <div className={`flex flex-col items-start flex-1 h-full relative overflow-x-hidden overflow-y-auto light-scroll gray-scroll`}>
            {/*  top bar  */}
            <TopBar chat={chat} user={user}/>
            <Outlet context={chat} />
        </div>
        <Chat user={user} chat={chat} setChat={setChat} context={{url: `company/${uri}`}} apiHandler={sideQuestChat} />
            {!chat && <button onClick={() => setChat(!chat)}
            className={"fixed bottom-0 right-0 m-4 material-symbols-rounded p-4 rounded-full bg-blue-400 text-white"}>chat</button>}
    </div>
}

export default SideQuestLayout;