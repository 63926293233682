import React, {useEffect, useRef, useState} from "react";
import {Helmet} from "react-helmet-async";
import Sidebar from "../components/Sidebar";
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import Nimbey from "./../static/img/logos/NimbeyLogo-dark.svg";
import Img from "../components/Img";
import AutoCompleteSearch from "../components/AutoCompleteSearch";
import {secure} from "../api/auth";
import {getCart, getLocations, nimbotChat, searchProducts} from "../api/dashboard";
import Chat from "../components/Chat";
import {connect, useDispatch} from "react-redux";
import cartSlice from "../redux/cartSlice";
import userDataSlice from "../redux/userDataSlice";

function DashboardLayout({cart, locations}) {

    const [user, setUser] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const navbar = useRef();

    // checks user authentication
    useEffect(() => {
        secure().then(([status, data]) => {
            if (status === 200) {
                setUser(data);
            } else navigate("/staging-app/login", {state: {ref: location.pathname}});
        });
    }, [navigate]);

    // chat visibility toggle
    const [chat, setChat] = useState(true);

    // search bar (autocomplete) states
    const [search, setSearch] = useState("");
    const [searching, setSearching] = useState(false);
    const [options, setOptions] = useState(undefined);

    const handleSearchSelect = (id) => {
        navigate(`/staging-app/dashboard/products/${id}`);
    }

    const handleSearchSubmit = (value) => {
        navigate(`/staging-app/dashboard/search?query=${encodeURIComponent(value)}`);
    }

    useEffect(() => {
        if (!search.length) return;
        setSearching(true);
        const timeout = setTimeout(() =>
                searchProducts(search)
                    .then(([status, data]) => {
                        if (status === 200) {
                            setOptions(data.products)
                        }
                    })
                    .finally(() => setSearching(false)),
            500);
        return () => clearTimeout(timeout);
    }, [search]);

    const dispatch = useDispatch();
    const [loadingCart, setLoadingCart] = useState(false);

    useEffect(() => {
        if (user && locations.length === 0) {
            getLocations().then(([status, data]) => {
                if (status === 200)
                    dispatch(userDataSlice.actions.updateState({locations: data}));
            });
        }
    }, [user]);

    useEffect(() => {
        if (user && cart.id === null) {
            setLoadingCart(true);
            getCart().then(([status, data]) => {
                if (status === 200) {
                    dispatch(cartSlice.actions.setCart(data));
                }
            }).catch(console.log).finally(() => {
                setLoadingCart(false);
            });
        }
    }, [user]);

    if (user)
        return <>
            <Helmet><title>Dashboard | Nimbey</title></Helmet>
            <div className={"flex items-start w-full h-full"}>
                {/* Sidebar */}
                <Sidebar chat={chat} setChat={setChat} user={user}/>
                <div
                    className={"flex flex-col items-start w-full flex-1 relative overflow-hidden h-full bg-gray-100"}>
                    <div id={"navbar"} ref={navbar}
                         className={"flex items-center justify-between w-full gap-2 sticky z-40 top-0 bg-white text-black shadow border-b p-4 lg:p-3 lg:px-8"}>
                        <div className={"flex items-center flex-1"}>

                            {/*  visible in mobile view  */}
                            <div className={"flex items-center gap-4"}>
                                <button onClick={() => document.getElementById("sideBar").classList.toggle('hidden')}
                                        className={"material-symbols-rounded rounded-full hover:lg:bg-gray-50 p-1 px-2 text-xl block lg:hidden"}>
                                    menu
                                </button>
                                {/* Nimbey logo on mobile devices */}
                                <Img src={Nimbey} alt={"Nimbey-logo"} className={"lg:hidden "}/>
                            </div>

                            {/*  search  */}
                            <div
                                className={"flex hidden lg:flex items-center gap-2 w-full max-w-screen-sm lg:max-w-sm absolute lg:relative p-2 px-3 lg:p-0 left-0 justify-evenly bg-white"}
                                id={"dashboard-search"}>
                                <AutoCompleteSearch
                                    placeholder={"Would you like to place a new request?"} searching={searching}
                                    onSelect={handleSearchSelect} onChange={v => setSearch(v)}
                                    onSubmit={handleSearchSubmit}
                                    inputClass={"bg-transparent p-1 w-full text-xs placeholder:text-gray-600"}
                                    listClass={"bg-white top-[38px] shadow border rounded-md max-h-64 overflow-y-auto light-scroll gray-scroll"}
                                    optionClass={"p-2 px-10 text-xs"}
                                    className={"max-w-sm w-full px-4 py-2 bg-gray-50 rounded-full flex items-center gap-2"}
                                    options={options?.map(o => ({id: o.product_id, name: o.product_name}))}
                                />
                                <button
                                    onClick={() => {
                                        document.getElementById("dashboard-search").classList.toggle('hidden');
                                    }}
                                    className={"material-symbols-rounded bg-gray-100 rounded-md p-2 lg:hidden"}>close
                                </button>
                            </div>

                        </div>
                        <div className={"flex items-center gap-4"}>
                            {/*  icons  */}
                            <div className={"flex items-center gap-4 pr-4 border-r"}>
                                <button onClick={(evt) => {
                                    document.getElementById("dashboard-search").classList.toggle("hidden")
                                }} className={"lg:hidden material-symbols-rounded text-xl"}>search
                                </button>
                                <Link to={"/staging-app/dashboard/orders/cart"} className={"relative"}>
                                    <span className={"material-symbols-rounded text-xl"}>
                                        shopping_cart
                                    </span>
                                    {!!cart.products.length && <div
                                        className={"w-2 rounded-full top-0 right-0 bg-red-600 aspect-square absolute z-10"}/>}
                                </Link>
                            </div>
                            {/* user card */}
                            <div className={"flex items-center gap-2"} tabIndex={1}>
                                {user.image && <img src={user.image}
                                                    className={"rounded-full w-9"} alt={"user"}/>}
                                {!user.image && <span
                                    className={"text-sm font-bold px-3 py-1.5 rounded-full bg-lime-400"}>{user.userName.split(" ").map(x => x[0])}</span>}
                                <div className={"hidden lg:flex flex-col items-start text-xs"}>
                                    <span className={"font-bold"}>{user.userName}</span>
                                    <span className={"text-xs font-normal"}>{user.email}</span>
                                </div>
                                <span className={"material-symbols-rounded text-md hidden lg:block"}>expand_more</span>
                            </div>

                        </div>
                    </div>
                    <div id={"outlet"}
                         className={"flex flex-col items-center justify-start p-2 py-6 lg:p-4 gap-3 overflow-hidden overflow-y-auto w-full flex-1 pb-20  light-scroll"}>
                        <Outlet context={{chat, user}}/>
                    </div>
                </div>
                <Chat user={user} apiHandler={nimbotChat} chat={chat} setChat={setChat} context={{}}
                      className={"lg:max-w-sm 2xl:max-w-md"}/>
                {!chat && <button onClick={() => setChat(!chat)}
                                  className={"fixed bottom-0 right-0 m-4 material-symbols-rounded p-4 rounded-full bg-blue-400 text-white"}>chat</button>}
            </div>
        </>

    return <div className={"flex flex-col flex-1 w-full items-center justify-center"}>
        <span className={"material-symbols-rounded animate-spin"}>progress_activity</span></div>

}

export default connect(state => ({locations: state.userData.locations, cart: state.cart}))(DashboardLayout);