import React from "react";
import {Outlet} from "react-router-dom";


export default function App() {
    return (
        <>
            <Outlet/>
            <div id="notiStack" className="fixed bottom-0 right-0" />
        </>
    )
}