import {formatFileSize} from "../views/dashboard/admin/AdminContracts";
import {Link, useOutletContext} from "react-router-dom";
import React from "react";


function ContractRecord({contract, statusUpdates, handleDeleteContract, handleDisableContract, setDescribeContract}) {
    const {chat} = useOutletContext();

    const active = statusUpdates[contract.id]?.active || contract.active;
    const status = active ? statusUpdates[contract.id]?.status || contract.status : "Disabled";
    const handleExpand = (ev) => ev.target?.parentElement?.nextSibling?.classList?.toggle(chat ? "expand-contract-xl" : "expand-contract-lg");
    const handleDetail = () => (contract.type.toString() === "0") ? setDescribeContract(contract) : null;

    return <div key={`contract-${contract.id}`}
                className={`flex flex-col ${chat ? "xl:flex-row" : "lg:flex-row"} items-center w-full card p-0 border`}>
        <div className={`flex w-full items-center flex-[4] p-4 ${!active ? 'opacity-60' : ''}`}>
            <div className={"flex flex-[2] gap-4 items-center overflow-hidden"}>
                {
                    contract.type.toString() === "0" ?
                        <span className={"material-symbols-rounded text-xl"}>article</span> :
                        <span className={"material-symbols-rounded text-xl"}>list_alt</span>
                }
                <div className={"flex flex-col w-full overflow-hidden"}>
                    <span onClick={handleDetail}
                          className={`font-semibold text-nowrap overflow-hidden text-ellipsis text-sm ${(contract.type.toString() === "0") ? 'cursor-pointer' : ''}`}>
                            {contract.name}</span>
                    <span className={"text-xs mt-1 text-gray-600"}>
                        {contract.type.toString() === "0" ? "General Contract" : "Pricing List"}</span>
                </div>
            </div>
            <div className={`hidden ${chat ? "xl:flex" : "lg:flex"} flex-col flex-1 items-start justify-center`}>
                <span className={"block flex-1 text-xs text-gray-600"}>{contract.format}</span>
                <span className={"block flex-1 text-xs text-gray-600"}>{formatFileSize(contract.size)}</span>
            </div>
            <div className={`hidden ${chat ? "xl:flex" : "lg:flex"} flex-1 items-center justify-start`}>
                <span className={"block flex-1 text-xs text-gray-600"}>{new Date(contract.created_at).toDateString()}</span>
            </div>
            <button onClick={handleExpand} className={`material-symbols-rounded ${chat ? "xl:hidden": "lg:hidden"}`}>
                expand_more
            </button>
        </div>
        <div className={`w-full flex ${chat ? "xl:flex-[2]" : "lg:flex-[2]"} hidden ${chat ? "xl:flex" : "lg:flex"} bg-gray-50 ${chat ? "xl:bg-white" : "lg:bg-white"} border-t p-4 ${chat ? "xl:border-none": "lg:border-none"} items-center`}>
            <div className={`flex flex-col ${chat ? "xl:flex-1": "lg:flex-1"} items-center`}>
                <button disabled={!active}
                        className={"border hover:bg-gray-100 hover:border-gray-100 rounded-md w-fit text-xs px-3 py-2 capitalize"}>
                    {status}
                </button>
            </div>
            <div className={"flex flex-1 items-center justify-end gap-4"}>
                {
                    contract.type === 0 &&
                    <button onClick={() => handleDeleteContract(contract.id)}
                            className={"material-symbols-rounded text-lg"}>delete</button>
                }
                {
                    status !== 'Processing' && contract.type === 1 &&
                    <button disabled={true}
                            className={"material-symbols-rounded text-lg disabled:text-gray-400"}>delete</button>
                }

                <Link className={"material-symbols-rounded text-lg"}
                      to={`${process.env.REACT_APP_API_URL}/api/admin/contracts/download?contract_id=${contract.id}`}
                      target={"_blank"} rel={"noreferrer"}>download</Link>

                <button onClick={() => handleDisableContract(contract.id, active)}
                        className={"material-symbols-rounded"}>{active ? "toggle_on" : "toggle_off"}
                </button>
            </div>
        </div>
    </div>
}

export default ContractRecord;